<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Assign Agent </span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-autocomplete
                outlined
                :search-input.sync="searchAgent"
                :error="$v.user.added_by.$error"
                v-model="user.added_by"
                dense
                placeholder="Type to search agent"
                :items="agents"
                item-text="full_name"
                item-value="id"
              >
                <template v-slot:label>
                  Select Agent <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.user.added_by.$error">This Field is required</span>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="mr-2" text dark medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="saveAgent()"
            :loading="loading"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserService from "@/services/user/UserService";
import {required} from "vuelidate/lib/validators";

const userService = new UserService();

export default {
  validations:{
      user:{
          added_by: {required}
      }
  },
  data() {
    return {
        errors: [],
        dialog: false,
        loading: false,
        agent: null,
        agents: [],
        user:{
            added_by: '',
            transferred_from: '',
        },
        user_id: '',
        searchAgent: ''
    }
  },
  methods: {
    assignAgent(agent){
      this.agent = agent;
      this.openDialog();
      if(this.agent) {
        this.user.transferred_from = this.agent.id;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    openDialog() {
      this.resetForm();
      this.dialog = true;
    },
    saveAgent(){
        this.$v.$touch();
        if (this.$v.$error) {
            setTimeout(() => {
            this.$v.$reset()
            }, 3000);
        }
        else {
            this.loading = true;

            userService
            .assignAgent(this.user_id, this.user)
            .then((response) => {
                if(response.data.status == "OK") {
                  this.$snotify.success('Agent assigned successfully.');
                  this.closeDialog();
                  this.$emit('refresh');
                }
            })
            .catch(() => {
                this.$snotify.success('Oops looks like something went wrong.');
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            });
        }
    },
    resetForm() {
        this.errors=[];
        this.user = {
            added_by: ''
        };
        this.$v.$reset();
    },
    getCurrentUserId() {
        this.user_id = this.$route.params.user_id;
    },
  },
  watch:{
    searchAgent(val){
      userService
        .getAgents(val)
        .then((response) => {
            this.agents = response.data.agent;
        })
        .catch((err) => {

        });
    }
  },
  mounted(){
    this.getCurrentUserId();
  }
}
</script>
