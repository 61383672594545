<template>
  <v-app>
    <v-card raised>
      <v-card-title>
        Application Counsellor
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2 btn btn-primary "
          dark
          small
          @click="createApplicantManager"
        >
          <v-icon x-small>
            fas fa-plus
          </v-icon>
          Assign Counsellor
        </v-btn>
      </v-card-title>

      <v-card-text>        
        <div class="table-responsive" style="table-layout: fixed">
          <v-skeleton-loader
              type="table-thead"
              v-if="loading">
          </v-skeleton-loader>

          <v-skeleton-loader
              v-if="loading"
              type="table-row-divider@4">
          </v-skeleton-loader>

          <table v-if="! loading" class="table">
            <thead>
              <tr class="px-3">
                  <th><strong>Name</strong></th>
                  <th><strong>Role</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr v-if="applicant_managers.length != 0" v-for="applicant in applicant_managers" :key="applicant.id" >
                  <td class="text-left font-weight-bolder font-size-lg">
                    {{applicant.admin_name}}
                  </td>
                  <td class="text-left font-weight-bolder font-size-lg">
                    ---
                  </td>
                  <td class="text-left font-weight-bolder font-size-lg">
                    <v-btn 
                      class="btn btn-danger" 
                      small
                      @click="deleteApplicantManager(applicant.id)"
                      color="red"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="applicant_managers.length == 0" >
                  <td colspan="4" class="text-center"><strong>No Data Found</strong></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </v-card-text>
    
    </v-card>

    <CreateAndUpdateApplicantManager
      ref="create-and-update-applicant-manager"
      @refresh="getApplicantManagers"
    ></CreateAndUpdateApplicantManager>

  </v-app>

</template>

<script>
import CreateAndUpdateApplicantManager from "./CreateAndUpdateApplicantManager";
import UserApplicantManagerService from "@/services/user/applicant-manager/UserApplicantManagerService";

const userApplicationManager = new UserApplicantManagerService();

export default {
  name: "ApplicantManager",
  components: {
    CreateAndUpdateApplicantManager
  },
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      loading: true,
      applicant_managers: [
      ],
    }
  },
  props: ['user_id'],
  methods:{
    createApplicantManager(){
      this.$refs['create-and-update-applicant-manager'].createApplicantManager(this.user_id);
    },
    getApplicantManagers(){
      this.loading = true;
      userApplicationManager
      .paginate(this.user_id, {}, this.page)
      .then((response) => {
        this.applicant_managers = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    deleteApplicantManager(applicantId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            userApplicationManager
            .delete(applicantId)
            .then((response) => {
                this.getApplicantManagers()
                this.$snotify.success("Application Counsellor Removed ");
            })
            .catch((err) => {
                this.$snotify.error("Oops something went wrong");
            });
          }
        }
      });
    }
  },
  mounted(){
    this.getApplicantManagers();
  }
}
</script>

<style scoped>

</style>
