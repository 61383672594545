<template>
  <v-app>
    <v-card raised>
      <v-card-title>

        English proficiency test score
        <v-spacer></v-spacer>
        <v-btn
        class="btn btn-primary"
            medium
                @click="createTestScore"
        >
          <v-icon x-small>
            fas fa-plus
          </v-icon>
          Add New
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-if="testScore.length > 0">
          <v-expansion-panel
                  v-for="(item,i) in testScore"
                  :key="i"
          >
            <v-expansion-panel-header>
              {{item.exam_type_text}}
              <div class="text-right mr-3">
                <v-btn text @click="deleteTestScore(item.id)"><v-icon class="mx-2" color="red" small>fas fa-trash</v-icon>Delete </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <strong>Exam Date</strong> : {{item.formatted_exam_date}}
                </v-col>
                <v-col cols="6">
                  <strong>Expire Date</strong> : {{item.formatted_expiry_date}}
                </v-col>
                <v-col cols="2">
                  <strong>Reading</strong> : {{item.reading}}
                </v-col>
                <v-col cols="2">
                  <strong>Writing</strong> : {{item.writing}}
                </v-col>
                <v-col cols="2">
                  <strong>Speaking</strong> : {{item.speaking}}
                </v-col>
                <v-col cols="2">
                  <strong>Listening</strong> : {{item.listening}}
                </v-col>
                <v-col cols="2">
                  <strong>Over All</strong> : {{item.total}}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" v-if="item.exam_type == 'gre' || item.exam_type == 'gmat'">
                  <strong>Verbal</strong> : {{item.verbal}}
                </v-col>
                <v-col cols="2" v-if="item.exam_type == 'gre' || item.exam_type == 'gmat'">
                  <strong>Writing Rank</strong> : {{item.writing_rank}}
                </v-col>
                <v-col cols="2" v-if="item.exam_type == 'gre' || item.exam_type == 'gmat'">
                  <strong>Verbal Rank</strong> : {{item.verbal_rank}}
                </v-col>
                <v-col cols="2" v-if="item.exam_type == 'gre' || item.exam_type == 'gmat'">
                  <strong>Quantitative</strong> : {{item.quantitative}}
                </v-col>
                <v-col cols="2" v-if="item.exam_type == 'gre' || item.exam_type == 'gmat'">
                  <strong>Quantitative Rank</strong> : {{item.quantitative_rank}}
                </v-col>
                <v-col cols="2" v-if="item.exam_type == 'gmat'">
                  <strong>Total Rank</strong> : {{item.total_rank}}
                </v-col>

              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row v-else>
          <v-col cols="12">
            <h3 class="text-center">No Data Found </h3>
          </v-col>
        </v-row>
        <b-pagination
            v-if="testScore.length > 0"
            class="pull-right mt-7"
            @input="getAllUserTestScore"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
        ></b-pagination>
      </v-card-text>
      <test-score ref="test-score" @refresh="getAllUserTestScore"></test-score>
    </v-card>
  </v-app>
</template>

<script>
import UserTestScoreService from "@/services/user/test-score/UserTestScoreService";
import TestScore from "@/view/pages/view/user/student/profile/components/test-score/TestScore";

const userTestScore = new UserTestScoreService();
export default {
  name: "UserTestScore",
  props: ['user_id'],
  components: {TestScore},
  data() {
    return {
      userId: '',
      testScore: [],
      pageCount: null,
      page: null,
      total: null,
      perPage: null,
      headers: [
        {
          text: 'User Name',
          align: 'start',
          sortable: false,
          value: 'user',
        },
        {text: 'Exam Type', value: 'exam_type_text'},
        {text: 'Exam Date', value: 'exam_date'},
        {text: 'Exam Expiry Date', value: 'expiry_date'},
        {text: 'Reading', value: 'reading'},
        {text: 'Writing', value: 'writing'},
        {text: 'Writing Rank', value: 'writing_rank'},
        {text: 'Speaking', value: 'speaking'},
        {text: 'Listening', value: 'listening'},
        {text: 'Verbal', value: 'verbal'},
        {text: 'Verbal Rank', value: 'verbal_rank'},
        {text: 'Quantitative', value: 'quantitative'},
        {text: 'Quantitative Rank', value: 'quantitative_rank'},
        {text: 'Total', value: 'total'},
        {text: 'Total Rank', value: 'total_rank'},
        {text: 'Action', value: 'action'},
      ]
    }
  },
  mounted() {
    this.userId = this.$route.params.user_id;
    if(this.userId == undefined) {
      this.userId = this.user_id;
    }
    this.getAllUserTestScore();
  },
  methods: {
    createTestScore() {
      this.$refs['test-score'].createTestScore(this.userId);
    },
    editTestScore(item) {
      this.$refs['test-score'].editTestScore(item);
    },
    getAllUserTestScore() {
      userTestScore.paginate(this.userId, {}, this.page).then(response => {
          this.testScore = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.pageCount = response.data.meta.last_page;
      }).catch(() => {});
    },
    deleteTestScore(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            userTestScore
                .delete(this.userId, id)
                .then(() => {
                  this.getAllUserTestScore()
                  this.$snotify.success("User Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>

<style scoped>

</style>