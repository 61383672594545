import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class UserEducationHistoryService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user';
    }

    sort(userId, data=[]){
        let url = `${this.#api}/${userId}/education-history/sort`;
        return apiService.post(url, data);
    }

    paginate(userId, data={},index = null) {
        let url = `${this.#api}/${userId}/education-history`;
        if (index != null)
            url = `${url}/?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    create(userId, data) {
        let url = `${this.#api}/${userId}/education-history`;
        return apiService.post(url, data)
    }

    show(userId, id) {
        let url = `${this.#api}/${userId}/education-history/${id}`
        return apiService.get(url)
    }

    update(userId, id, data) {
        let url = `${this.#api}/${userId}/education-history/${id}`
        return apiService.put(url, data)
    }

    delete(userId, id){
        let url = `${this.#api}/${userId}/education-history/${id}`
        return apiService.delete(url);
    }
}