<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        {{ title }} Employment History
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="history.title"
                :error="$v.history.title.$error"
                outlined
                dense
            >
              <template v-slot:label>
                Title <span class="text-danger">*</span>
              </template>
            </v-text-field>
            <span class="text-danger" v-if="$v.history.title.$error">Title is required</span>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                v-model="history.employment_type"
                :error="$v.history.employment_type.$error"
                :items="employment_types"
                item-text="name"
                item-value="value"
                outlined
                dense
            >
              <template v-slot:label>
                Employment Type <span class="text-danger">*</span>
              </template>
            </v-select>
            <span class="text-danger" v-if="$v.history.employment_type.$error">Employment Type is required</span>
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="history.organisation"
                :error="$v.history.title.$error"
                outlined
                dense
            >
              <template v-slot:label>
                Organisation <span class="text-danger">*</span>
              </template>
            </v-text-field>
            <span class="text-danger" v-if="$v.history.organisation.$error">Organisation is required</span>
          </v-col>
          <v-col cols="5">
            <v-menu
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                ref="menu"
                transition="scale-transition"
                v-model="menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    dense
                    label="Start date"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-model="history.start_from"
                    v-on="on"
                    :error="$v.history.start_from.$error">
                  >
                  <template v-slot:label>
                    Start date<span
                      class="text-danger">*</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                  :min="currentDate"
                  no-title
                  @input="menu = false"
                  v-model="history.start_from"
              ></v-date-picker>
            </v-menu>
            <span class="text-danger" v-if="$v.history.start_from.$error">Start date is required</span>
          </v-col>
          <v-col cols="2">
            <v-switch
                v-model="history.currently_working"
                label="Currently Working"
                @input="menu = false"
                @change="history.end_date = null"
            ></v-switch>
          </v-col>
          <v-col cols="5" v-if="!history.currently_working">
            <v-menu
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                ref="menu"
                transition="scale-transition"
                v-model="menu1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    dense
                    label="End date"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-model="history.end_date"
                    v-on="on"
                    :error="!history.currently_working && $v.history.end_date.$error"
                >
                  <template v-slot:label>
                    End date<span
                      class="text-danger">*</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                  :min="currentDate"
                  no-title
                  @input="menu1 = false"
                  v-model="history.end_date"
              ></v-date-picker>
            </v-menu>
            <span class="text-danger" v-if="!history.currently_working && $v.history.end_date.$error">End date is required</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="mr-2" text dark medium @click="resetForm">Close</v-btn>
        <v-btn depressed class="btn btn-primary" medium @click="createOrUpdate">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserEmploymentHistoryService from "@/services/user/employment-history/UserEmploymentHistoryService";

const historyService = new UserEmploymentHistoryService();
export default {
  name: "CreateOrUpdate",
  props: ['user_id', 'dialog'],
  validations() {
    return {
      history: this.history_rule
    }
  },
  data() {
    return {
      title: null,
      history: {
        title: null,
        organisation: null,
        start_from: null,
        end_date: null,
        employment_type: '',
        currently_working: false
      },
      employment_types: [
        {name: "Full Time", value: "full_time"},
        {name: "Part Time", value: "part_time"},
      ],
      menu: false,
      menu1: false,
      edit: false,
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  }, computed: {
    history_rule() {
      let rule = {
        title: {required},
        organisation: {required},
        employment_type: {required},
        start_from: {required},
      };
      if (!this.history.currently_working) {
        rule.end_date = {required}
      }
      return rule;
    }
  }, methods: {
    resetForm() {
      this.history = {
        title: null,
        organisation: null,
        start_from: null,
        end_date: null,
        employment_type: '',
        currently_working: false
      }
      this.$v.history.$reset();
      this.edit = false
      this.$emit('close_dialog')
    },
    createOrUpdate() {
      this.$v.history.$touch();
      if (this.$v.history.$error) {
        setTimeout(() => {
          this.$v.history.$reset();
        }, 3000)
      } else {
        if (this.edit) {
          this.update();
        } else {
          this.create();
        }
      }
    },
    update() {
      this.loading = true
      historyService.update(this.user_id, this.history.id, this.history).then(response => {
        this.$snotify.success("Employment history create successfully");
        this.resetForm();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later");
      }).finally(this.loading = false)
    },
    create() {
      this.loading = true
      historyService.create(this.user_id, this.history).then(response => {
        this.$snotify.success("Employment history create successfully");
        this.resetForm();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later");
      }).finally(this.loading = false)
    },
    assignHistory(history) {
      if (history.id)
        this.edit = true
      this.history = history;
    }
  }

}
import {required} from "vuelidate/lib/validators";
</script>

<style scoped>

</style>