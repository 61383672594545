<template>
  <v-row >
    <v-col cols="12" v-if="item.application_type == 'academic'">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>
            <v-row>
              <v-col cols="4">
                <v-select
                    label="Select Application Action"
                    v-model="field"
                    :items="fields"
                    item-text="name"
                    item-value="value"
                    outlined dense
                ></v-select>
              </v-col>
              <v-col cols="8">
                <v-btn
                    color="blue text-white"
                    rounded
                    large
                    @click="updateApplication(item, field)"
                >
                  Go
                </v-btn>
                <v-btn
                    class="ml-4 text-white"
                    :color="item.is_archived ? 'red' : 'blue'"
                    rounded
                    large
                    @click="updateArchivedApplication(item)"
                >
                  {{item.is_archived ? "UnArchived" : "Archived"}}
                </v-btn>
                <v-btn
                    v-if="!item.is_valid_offer"
                    class="mx-4 text-white"
                    :color="!item.is_valid_offer ? 'blue' : 'red'"
                    rounded
                    large
                    @click="updateValidOfferApplication(item)"
                >
                  {{!item.is_valid_offer ? "Valid for offer" : "Not valid offer"}}
                </v-btn>
                <v-btn
                    v-if="item.is_valid_offer"
                    class="mx-4 text-white"
                    color="green"
                    rounded
                    large
                >
                  Valid for offer
                </v-btn>

                <v-btn
                    v-if="item.application_status == 'visa_applied'"
                    color="green text-white"
                    rounded
                    large
                >
                 Applied visa & waiting
                </v-btn>
                <v-btn
                    v-else
                    color="blue text-white"
                    rounded
                    large
                    @click="updateApplicatonStatus(item)"
                >
                  Applied visa & waiting
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>

    <v-col cols="12" v-else>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Case Officer Contact Date</v-list-item-title>
          <v-list-item-subtitle>
            {{ item.formatted_co_latest_contact_date ? item.formatted_co_latest_contact_date : 'N/A' }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Visa Application Date</v-list-item-title>
          <v-list-item-subtitle>
            {{ item.formatted_visa_application_date ? item.formatted_visa_application_date : 'N/A' }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Visa Decision Date</v-list-item-title>
          <v-list-item-subtitle>
            {{ item.formatted_visa_decision_date ? item.formatted_visa_decision_date : 'N/A' }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>

    <v-col cols="12">
      <v-timeline
          clipped
          dense
          v-if="histories && histories.length>0"
      >
        <v-timeline-item

            :key="history.id"
            class="mb-4"
            color="grey"
            icon-color="grey lighten-2"
            small
            v-for="(history, index) in histories"
        >
          <v-row justify="space-between">
            <v-col cols="8">
              <v-chip
                  class="white--text ml-0"
                  :color="history.status_color"
                  label
                  small
              >
                {{ history.application_status_title }}
              </v-chip>
              <v-btn
                  v-if="index != 0 && index == histories.length-1"
                  class="ml-2"
                  icon
                  small
                  @click="deleteHistory(history.id, item.id)"
                  color="dark-blue"
              >
                <v-icon dark>
                  mdi-delete
                </v-icon>
              </v-btn>

              <div v-if="history.note" class="mt-2">Note / Comments :</div>
              <div v-if="history.note" class="breadcrumb">
                <span v-html="history.note"></span>
                <p class="font-weight-bold" v-if="history.application_status_state_id == 20 || history.application_status_state_id == 22 || history.application_status_state_id == 23">Attachment received</p>
              </div>

              <div v-if="history.attachment" class="subtitle mt-3">
                Attachments
              </div>
              <v-btn
                  class="white--text d-block ml-0 mt-2"
                  v-if="history.attachment"
                  v-for="(file, index) in history.attachment_paths"
                  :key="index"
                  color="green lighten-1"Countries
                  small
                  @click="openAttachment(file)"
              >
                Attachment {{ index + 1 }}
                <i class="fa fa-download ml-2 text-white"></i>
              </v-btn>

            </v-col>
            <v-col
                class="text-right"
                cols="4"
            >
              <span> <i class="fa fa-clock"></i> {{ history.created_date }} {{
                  history.created_time
                }} </span>
              <br>
              By {{ history.updated_by_business_name || 'ApplyCan' }}
            </v-col>
          </v-row>
        </v-timeline-item>

      </v-timeline>
      <strong v-else>
        Process Not Started Yet
      </strong>
    </v-col>

    <v-col cols="12" class="text-right">
      <div>
        <v-btn @click="updateStatus(item, histories)" small rounded class="btn btn-primary ml-2">
          <i class="fa fa-chart-bar"></i> Application Progress
        </v-btn>
        <v-btn v-if="item.application_status != 'granted'" @click="updateApplicationStatus(item)" small rounded class="btn btn-primary ml-2">
          <i class="fa fa-file-archive"></i> Visa Decision
        </v-btn>
      </div>
    </v-col>

    <update-application-history
        :country_id="item.country_id"
        :application_type="item.application_type"
        @refresh="getStatusHistories"
        @refresh-application="refreshApplication"
        ref="update-application-history"
    ></update-application-history>

    <update-application
        ref="application-update"
        :dialog="application_dialog"
        @close_application_dialog="closeApplicationDialog"
    ></update-application>

    <update-institution-and-program
        :dialog="update_institution_dialog"
        ref="update-institution-and-program"
        @refresh="closeApplicationDialog"
    >
    </update-institution-and-program>

    <application-status  ref="update-application-status" @refresh="closeApplicationDialog"></application-status>
  </v-row>
</template>

<script>
import UpdateApplicationHistory
  from "@/view/pages/view/user/student/profile/components/application/UpdateApplicationHistory.vue";
import UpdateApplication from "@/view/pages/view/user/student/profile/components/application/UpdateApplication";
import UpdateInstitutionAndProgram
  from "@/view/pages/view/user/student/profile/components/application/UpdateInstitutionAndProgram";
import ApplicationHistoryService from "@/services/application/history/ApplicationHistoryService";
import ApplicationService from "@/services/application/ApplicationService";
import ApplicationStatus from "@/view/pages/view/application/detail/ApplicationStatus";

const application = new ApplicationService();
const applicationHistory = new ApplicationHistoryService();
export default {
  name: "ApplicationHistory",
  props: ['histories', 'item'],
  data() {
    return {
      field: null,
      update_institution_dialog: false,
      application_dialog: false,
      fields: [
        { name: "Institution & Program", value: "institutions-program" },
        { name: "Applied Session", value: "sessions" },
        { name: "Offer", value: "offer" },
        { name: "Medical Date", value: "medical" },
        { name: "Private Status", value: "status" },
        { name: "Biometric Date", value: "biometric" },
        { name: "Visa status", value: "visa" },
        { name: "Defer Session", value: "deferred" },
        { name: "Refund", value: "refund" },
        { name: "Other", value: "other" },
      ]
    }
  },
  components: {
    ApplicationStatus,
    UpdateApplicationHistory,
    UpdateApplication,
    UpdateInstitutionAndProgram,
  },
  methods: {
    closeApplicationDialog() {
      this.application_dialog = false;
      this.update_institution_dialog = false;
      this.application = {}
      this.$emit('update-list')
    },
    refreshApplication() {
      this.$emit('update-list');
    },
    updateInstitutionAndProgram(application) {
      this.update_institution_dialog = true
      this.$refs['update-institution-and-program'].callApis(application)
    },
    openAttachment(file) {
      if(file && file.real) {
        window.open(file.real);
      }
    },
    updateApplicationStatus(application) {
      this.$refs['update-application-status'].openDialog(application);
    },
    getStatusHistories(applicationId) {
      application
          .getHistories(applicationId)
          .then((response) => {
            this.histories = response.data.data;
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    updateStatus(application, histories) {
      let visaApplicationStatusId = histories.length>0 && histories[0].visa_status_id ? histories[0].visa_status_id : null
      this.$refs['update-application-history'].updateApplicationHistory(application, visaApplicationStatusId);
    },
    updateApplication(item, field) {
      if(field) {
        this.application_dialog = true
        this.application = item
        this.$refs['application-update'].callApis(item, field)
      }else {
        this.$snotify.error("Please Select Option !!");
      }
    },
    updateArchivedApplication(item) {
      this.$confirm({
        message: `Are you sure to want ${item.is_archived ? "UnArchived" : "Archived"} application ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.is_archived = !item.is_archived;
            application
                .update(item.id, item)
                .then((response) => {
                  this.$emit("update-list");
                  this.$snotify.success("Update Successfully.");
                })
                .catch((err) => {
                });
          }
        },
      });
    },
    updateValidOfferApplication(item) {
      this.$confirm({
        message: `Are you sure that you want ${!item.is_valid_offer ? "valid for offer" : "not valid offer"} application ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            if(!item.is_draft && item.is_ready_to_submit && item.is_ready_to_submit) {
              let data = {};
              data.is_valid_offer = !item.is_valid_offer;
              application
                  .update(item.id, data)
                  .then(() => {
                    this.$emit("update-list");
                    this.$snotify.success("Update Successfully.");
                  })
                  .catch(() => {
                  });
            } else {
              this.$snotify.error("Application should be submitted.");
            }
          }
        },
      });
    },
    deleteHistory(historyId, applicationId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            applicationHistory
                .delete(historyId)
                .then((response) => {
                  // this.getVisaApplications();
                  this.$emit("update-list");
                  this.getStatusHistories(applicationId);
                  this.$snotify.success("Status Deleted Successfully.");
                })
                .catch((err) => {
                });
          }
        },
      });
    },
    updateApplicatonStatus(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.application_status = 'visa_applied';
            application
                .update(item.id, item)
                .then(() => {
                  this.$emit("update-list");
                  this.$snotify.success("Update Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>

<style scoped>
.breadcrumb {
  display: block;
}
.row {
  margin: -4px !important;
}
</style>