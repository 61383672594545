<template>
    <v-app>
        <v-card flat>
            <v-card-title>
                Tasks manager
                <v-spacer></v-spacer>
                <v-btn dark class="btn btn-primary"
                       @click="addTask"
                >
                    Add new task
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                <!--            <v-btn-->
                <!--                    class="ma-2"-->
                <!--                    outlined-->
                <!--                    small-->

                <!--            >-->
                <!--                <v-icon color="indigo" x-small>-->
                <!--                    fas fa-circle-->
                <!--                </v-icon>-->
                <!--                Today (0)-->
                <!--            </v-btn>-->
                <!--            <v-btn-->
                <!--                    class="ma-2"-->
                <!--                    outlined-->
                <!--                    small-->

                <!--            >-->
                <!--                <v-icon color="yellow" x-small>-->
                <!--                    fas fa-circle-->
                <!--                </v-icon>-->
                <!--                Upcomming (0)-->
                <!--            </v-btn>-->
                <!--            <v-btn-->
                <!--                    class="ma-2"-->
                <!--                    outlined-->
                <!--                    small-->

                <!--            >-->
                <!--                <v-icon color="green" x-small>-->
                <!--                    fas fa-circle-->
                <!--                </v-icon>-->
                <!--                Attended (0)-->
                <!--            </v-btn>-->
                <!--            <v-btn-->
                <!--                    class="ma-2"-->
                <!--                    outlined-->
                <!--                    small-->

                <!--            >-->
                <!--                <v-icon color="red" x-small>-->
                <!--                    fas fa-circle-->
                <!--                </v-icon>-->
                <!--                Missed (0)-->
                <!--            </v-btn>-->
            </v-card-subtitle>
            <v-card-text>
                <v-data-table
                        :headers="headers"
                        :items="tasks"
                        :items-per-page="5"
                        class="elevation-1"
                >
                    <template v-slot:item.title="{ item }">
                        <a class="link" @click="editTask(item.id)"
                        >
                            {{ item.title }}
                        </a>
                    </template>
                    <template v-slot:item.priority="{ item }">
                        <v-btn x-small v-if="item.priority == 'high'" :color="item.priority == 'high' ? 'red' : '' " :dark="item.priority == 'high'"  rounded
                               class="my-1" depressed>
                            <div class="d-flex justify-content-between">
                                <v-icon class="mr-4" small>fas fa-star</v-icon>
                                <span>Important</span>
                            </div>
                        </v-btn>
                        <v-btn x-small v-if="item.priority == 'normal' " :color="item.priority == 'normal' ? 'blue' : '' " :dark="item.priority == 'normal'" rounded
                               class="my-1" depressed>
                            <div class="d-flex justify-content-between">
                                <v-icon class="mr-4" small>fas fa-star</v-icon>
                                <span>Normal</span>
                            </div>
                        </v-btn>
                    </template>
                    <template v-slot:item.status="{ item }">

                        <v-btn x-small v-if="item.status == 'process'" :color="item.status == 'process' ? 'blue' : '' " :outlined="item.status == 'process'"  rounded
                               class="my-1"
                               depressed>
                            <div class="d-flex justify-content-between">
                                <v-icon class="mr-4" x-small>mdi-trending-up</v-icon>
                                <span>In Progress</span>
                            </div>
                        </v-btn>
                        <v-btn x-small v-if="item.status == 'complete'"   :color="item.status == 'complete' ? 'green' : '' " :outlined="item.status == 'complete'"  rounded
                               class="my-1"
                               depressed>
                            <div class="d-flex justify-content-between">
                                <v-icon class="mr-4" x-small>fas fa-check</v-icon>
                                <span>Completed</span>
                            </div>
                        </v-btn>
                    </template>
                    <template v-slot:item.due_date="{ item }">

                        {{ item.due_date | moment("from", "now") }}

                    </template>
                </v-data-table>
            </v-card-text>

        </v-card>
        <task-detail ref="task-detail"  :applicant_id="user_id" @refresh="__getAll"> </task-detail>
    </v-app>
</template>

<script>
    import TaskService from "@/services/task-manager/task/TaskService";
    const taskService  = new TaskService();

    import TaskDetail from "./TaskDetail";
    export default {
        name: "taskList",
        props:['user_id'],
        components:{
            TaskDetail
        },
        data() {
            return {
                loading: false,
                tasks:[],
                total: null,
                perPage: null,
                page: null,
                search:{
                    applicant_id:null
                },
                headers: [
                    {
                        text: 'Title',
                        align: 'start',
                        sortable: false,
                        value: 'title',
                    },
                    { text: 'Status', value: 'status' },
                    { text: 'Priority', value: 'priority' },
                    { text: 'Applicant', value: 'applicant' },
                    { text: 'Due Date', value: 'due_date' },
                ],

            }
        },
        methods: {
            addTask() {
                this.$refs['task-detail'].open();
            },
            editTask(id) {
                this.$refs['task-detail'].edit(id);
            },
            __getAll(){
                this.loading = true;
                taskService
                    .paginate(this.search,this.page)
                    .then(response => {
                        this.tasks = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                        this.loading = false;
                    })
                    .catch((err) => {

                        this.loading = false;
                        this.$snotify.error("Oops something went wrong");
                    });
            },
        },
        mounted() {
            this.search.applicant_id = this.user_id;
            this.__getAll();
        }
    }
</script>

<style scoped>

</style>