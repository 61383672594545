<template>
  <v-dialog persistent
            max-width="600"
            scrollable
            v-model="dialog"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Application Processing</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="6" v-for="(application, index) of application_types" :key="index">
              <v-btn class="btn" @click="onApplicationTypeSelect(application.value, index)" style="width: 100%"
                     :class="application_type_index ==index?'btn-primary text-white':'btn-default text-gray'">
                {{ application.value }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-autocomplete 
                autocomplete="false"
                outlined
                dense
                v-model="application_visa.country_id"
                :items="countries"
                item-text="title"
                item-value="id"
                :error="$v.application_visa.country_id.$error"
                @change="handleCountryChange"
              >
                <template v-slot:label>Select Country <span class="text-danger">*</span></template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.application_visa.country_id.$error">This Field is required</span>
              <span class="text-danger" v-if="errors.country_id">{{ errors.country_id[0] }}</span>
            </v-col>
            <template v-if="application_type_index == 0">
              <v-col cols="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.institution_id"
                                :items="institutions"
                                item-text="title"
                                item-value="id"
                                :loading="isCountryChanged"
                                :error="$v.application_visa.institution_id.$error"
                                @change="handleInstitutionChange"
                >
                  <template v-slot:label>Institution <span class="text-danger">*</span></template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.application_visa.institution_id.$error">This Field is required</span>
                <span class="text-danger" v-if="errors.institution_id">{{ errors.institution_id[0] }}</span>
              </v-col>
              <v-col cols="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.education_level_id"
                                :items="educationLevels"
                                item-text="title"
                                item-value="id"
                                :error="$v.application_visa.education_level_id.$error"
                                @change="handleInstitutionLevelChange"
                >
                  <template v-slot:label>Education Level <span class="text-danger">*</span></template>
                </v-autocomplete>
                <span class="text-danger"
                      v-if="$v.application_visa.education_level_id.$error">This Field is required</span>
                <span class="text-danger" v-if="errors.education_level_id">{{ errors.education_level_id[0] }}</span>
              </v-col>

              <v-col cols="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.academic_program_session_id"
                                :items="programSessions"
                                item-text="title"
                                item-value="id"
                                :error="$v.application_visa.academic_program_session_id.$error"
                                @change="handleSessionChange"
                                clearable
                >
                  <template v-slot:label> Academic Session <span class="text-danger">*</span></template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.application_visa.academic_program_session_id.$error">This Field is required</span>
                <span class="text-danger"
                      v-if="errors.academic_program_session_id">{{ errors.academic_program_session_id[0] }}</span>
              </v-col>

              <v-col cols="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.academic_program_id"
                                :items="programs"
                                item-text="title"
                                :error="$v.application_visa.academic_program_id.$error"
                                item-value="id"
                                :loading="isInstitutionChanged"
                >
                  <template v-slot:label>Program <span class="text-danger">*</span></template>
                </v-autocomplete>
                <span class="text-danger"
                      v-if="$v.application_visa.academic_program_id.$error">This Field is required</span>
                <span class="text-danger" v-if="errors.academic_program_id">{{ errors.academic_program_id[0] }}</span>
              </v-col>

            </template>
            <template v-if="application_type_index == 1">

              <v-col cols="12" md="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.state_id"
                                :items="states"
                                item-text="name"
                                item-value="id"
                                :error="$v.application_visa.state_id.$error"
                                @change="getAllCity"
                >
                  <template v-slot:label>Select State <span class="text-danger">*</span></template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.application_visa.state_id.$error">This Field is required</span>
                <span class="text-danger" v-if="errors.state_id">{{ errors.state_id[0] }}</span>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.city_id"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                :error="$v.application_visa.city_id.$error"
                >
                  <template v-slot:label>Select City <span class="text-danger">*</span></template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.application_visa.city_id.$error">This Field is required</span>
                <span class="text-danger" v-if="errors.city_id">{{ errors.city_id[0] }}</span>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.application_type_id"
                                :items="application_type_list"
                                item-text="title"
                                item-value="id"
                                :error="$v.application_visa.application_type_id.$error"
                                @change="getVisaTypes"
                >
                  <template v-slot:label>Application Type <span class="text-danger">*</span></template>
                </v-autocomplete>
                <span class="text-danger"
                      v-if="$v.application_visa.application_type_id.$error">This Field is required</span>
                <span class="text-danger" v-if="errors.application_type_id">{{ errors.application_type_id[0] }}</span>
              </v-col>

              <v-col cols="12" md="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.application_permit_id"
                                :items="visa_types"
                                item-text="title"
                                item-value="id"
                                :error="$v.application_visa.application_permit_id.$error"
                >
                  <template v-slot:label>Application Permit <span class="text-danger">*</span></template>
                </v-autocomplete>
                <span class="text-danger"
                      v-if="$v.application_visa.application_permit_id.$error">This Field is required</span>
                <span class="text-danger" v-if="errors.application_permit_id">{{
                    errors.application_permit_id[0]
                  }}</span>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.skill_occupation_id"
                                :items="skill_occupations"
                                item-text="title"
                                item-value="id"
                >
                  <template v-slot:label>Skill Occupation <span class="text-danger"></span></template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.nomination_category_id"
                                :items="nomination_categories"
                                item-text="title"
                                item-value="id"
                >
                  <template v-slot:label>Nomination Categories <span class="text-danger"></span></template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete autocomplete="false"
                                outlined
                                dense
                                v-model="application_visa.nomination_sub_class_id"
                                :items="nomination_sub_classes"
                                item-text="title"
                                item-value="id"
                >
                  <template v-slot:label>Nomination Sub-class <span class="text-danger"></span></template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="application_visa.hap_id" outlined dense>
                  <template v-slot:label>Hap Id<span class="text-danger">*</span></template>
                </v-text-field>
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete autocomplete="false"
                              outlined
                              dense
                              v-model="application_visa.user_applicant_manager_id"
                              :items="managers"
                              item-text="admin_name"
                              item-value="id"
                              :error="$v.application_visa.user_applicant_manager_id.$error"
              >
                <template v-slot:label> Select Applicant Manager
                  <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.application_visa.user_applicant_manager_id.$error">This Field is required</span>
              <span class="text-danger" v-if="errors.user_applicant_manager_id">{{
                  errors.user_applicant_manager_id[0]
                }}</span>
            </v-col>

            <v-col cols="12" v-if="application_visa.country_id && application_visa.application_type">
              <v-select
                  outlined
                  dense
                  v-model="application_visa.visa_status_id"
                  :items="visa_application_statuses"
                  item-text="title"
                  item-value="id"
                  :loading="isStatusLoading"
                  :error="$v.application_visa.visa_status_id.$error"
                  @change="handleVisaStatusChange"
              >
                <template v-slot:label> Select Visa Application Status <span class="text-danger">*</span></template>
              </v-select>
              <span class="text-danger"
                    v-if="$v.application_visa.visa_status_id.$error">This Field is required</span>
              <span class="text-danger" v-if="errors.visa_status_id">{{ errors.visa_status_state_id[0] }}</span>
            </v-col>

            <v-col cols="12" v-if="application_visa.visa_status_id">
              <v-select
                  outlined
                  dense
                  v-model="application_visa.visa_status_state_id"
                  :items="visaApplicationStatuses"
                  item-text="title"
                  item-value="id"
                  :loading="isStatusLoading"
                  :error="$v.application_visa.visa_status_state_id.$error"
              >
                <template v-slot:label> Select Processing Status <span class="text-danger">*</span></template>
              </v-select>
              <span class="text-danger"
                    v-if="$v.application_visa.visa_status_state_id.$error">This Field is required</span>
              <span class="text-danger" v-if="errors.visa_status_id">{{ errors.visa_status_state_id[0] }}</span>
            </v-col>

            <v-col cols="3">
              <v-checkbox v-model="application_visa.is_ready_to_submit" label="Ready For Submit"></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="application_visa.is_ready_for_visa" label="Ready For Visa"></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="application_visa.is_ready_enroll" label="Offer Issued"></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="application_visa.is_submitted_school" label="Submitted To Institution"></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="application_visa.is_enrolment_confirmed" label="Fee Payment Received"></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="application_visa.notify_student" label="Notify Student"></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="application_visa.notify_agent" label="Notify Agent"></v-checkbox>
            </v-col>

            <!-- <v-col cols="12" sm="12" md="12">
              <v-checkbox v-model="application_visa.send_email">
                <template v-slot:label>
                  <span class="text-left font-weight-medium">
                    Send email upon completion
                  </span>
                </template>
              </v-checkbox>
            </v-col> -->
            
            <!-- <v-col cols="12" v-if="application_visa.send_email">
              <v-combobox
                  v-model="emails" prepend-inner-icon="mdi-send"
                  hide-selected outlined dense deletable-chips
                  label="Emails*"
                  multiple small-chips>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Press <kbd>enter</kbd> to create a new Email
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col> -->

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            @click="closeDialog"
            color="mr-2" text dark
            medium
        >
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            @click="saveApplicationVisa"
            medium
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import CountryService from "@/services/country/CountryService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
import AcademicProgramSessionService from "@/services/academic/program/session/AcademicProgramSessionService";
import ApplicationService from "@/services/application/ApplicationService";
import VisaApplicationStatusService from "@/services/visa/application-status/VisaApplicationStatusService";
import {required} from "vuelidate/lib/validators";
import UserApplicantManagerService from "@/services/user/applicant-manager/UserApplicantManagerService";
import StateService from "@/services/country/state/StateService";
import CityService from "@/services/country/city/CityService";
import VisaTypeService from "@/services/visa/type/VisaTypeService";
import ApplicationTypeService from "@/services/application/type/ApplicationTypeService";
import SkillOccupationService from "@/services/skill-occupation/SkillOccupationService";
import NominationCategoryService from "@/services/nomination/nomination-category/NominationCategoryService";
import NominationSubclassService from "@/services/nomination/nomination-subclass/NominationSubclassService";

const country = new CountryService();
const institutionService = new InstitutionService();
const program = new AcademicProgramService();
const programSession = new AcademicProgramSessionService();
const application = new ApplicationService();
const visaApplicationStatusService = new VisaApplicationStatusService();
const educationLevel = new EducationLevelService();
const userApplicationManager = new UserApplicantManagerService();
const stateService = new StateService();
const cityService = new CityService();
const visaTypeService = new VisaTypeService();
const applicationTypeService = new ApplicationTypeService();
const skillOccupationService = new SkillOccupationService();
const nominationSubClassService = new NominationSubclassService();
const nominationCategory = new NominationCategoryService();

export default {
  validations() {
    return {
      application_visa: this.application_visa_rule
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      countries: [],
      institutions: [],
      programs: [],
      programSessions: [],
      visaApplicationStatuses: [],
      educationLevels: [],
      application_type_index: 0,
      visa_application_statuses: [],
      application_visa: {
        applicant_id: null,
        country_id: null,
        institution_id: null,
        academic_program_id: null,
        academic_program_session_id: null,
        processed_by: null,
        user_applicant_manager_id: null,
        visa_status_id: null,
        visa_status_state_id: null,
        auto_generate: true,
        new_password: '',
        application_type: 'academic',
        is_ready_to_submit: null,
        is_ready_for_visa: null,
        is_ready_enroll: null,
        is_submitted_school: null,
        is_enrolment_confirmed: null,
        notify_student: null,
        notify_agent: null
      },
      errors: [],
      isCountryChanged: false,
      isInstitutionChanged: false,
      isStatusLoading: false,
      user_id: null,
      managers: [],
      application_types: [
        {title: 'Academic Processing', value: "academic"},
        {title: 'Visa Processing', value: "visa"},
      ],
      states: [],
      cities: [],
      visa_types: [],
      application_type_list: [],
      skill_occupations: [],
      nomination_categories: [],
      nomination_sub_classes: [],
    }
  },
  mounted() {
    this.getCountries();
  },
  computed: {
    application_visa_rule() {
      let rule = {}
      if (this.application_type_index == 0) {
        rule = {
          country_id: {required},
          institution_id: {required},
          academic_program_id: {required},
          academic_program_session_id: {required},
          education_level_id: {required},
          user_applicant_manager_id: {required},
          visa_status_state_id: {required},
          visa_status_id: {required}
        }
      } else {
        rule = {
          country_id: {required},
          state_id: {required},
          city_id: {required},
          application_permit_id: {required},
          application_type_id: {required},
          user_applicant_manager_id: {required},
          hap_id: {required},
          visa_status_id: {required},
          visa_status_state_id: {required}
        }
      }
      return rule;
    }
  },
  methods: {
    createVisaApplication(user_id) {
      this.user_id = user_id;
      this.getUserApplicantManagers();
      this.getCountries();
      this.dialog = true;
    },
    getUserApplicantManagers() {
      userApplicationManager
          .all(this.user_id)
          .then((response) => {
            this.managers = response.data.data;
          })
          .catch((err) => {

          });
    },
    closeDialog() {
      this.resetForm();
      this.dialog = false;
      this.loading = false;
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.application_visa = {
        applicant_id: null,
        country_id: null,
        institution_id: null,
        academic_program_id: null,
        academic_program_session_id: null,
        processed_by: null,
        education_level_id: '',
        user_applicant_manager_id: null,
        visa_status_id: null,
        visa_status_state_id: null,
        auto_generate: true,
        new_password: '',
        application_type: 'academic',
        is_ready_to_submit: null,
        is_ready_for_visa: null,
        is_ready_enroll: null,
        is_submitted_school: null,
        is_enrolment_confirmed: null,
        notify_student: null,
        notify_agent: null
      };
      this.application_visa.country_id = null;
      this.application_visa.institution_id = null;
      this.application_visa.education_level_id = '';
      this.application_visa.academic_program_id = null;
      this.application_visa.academic_program_session_id = null;
      this.application_visa.visa_status_id = null;
      this.countries = [];
      this.institutions = [];
      this.programs = [];
      this.programSessions = [];
      this.visaApplicationStatuses = [];
      this.educationLevels = [];
    },
    getCountries() {
      country
          .getAllCountry({'is_operating_country': 1})
          .then(response => {
            this.countries = response.data.data;
            this.loading = false;
          })
          .catch((err) => {
          });
    },
    handleCountryChange() {
      this.isCountryChanged = true;
      this.isStatusLoading = true;
      this.institutions = [];
      this.educationLevels = [];
      this.programs = [];
      this.programSessions = [];
      this.getEducationLevels();
      this.getInstitutions();
      this.getVisaApplicationStatus();
      this.getStates();
      this.getApplicationTypes();
      this.getNominationCategory();
      this.getNominationSubclass();
      this.getSkillOccupation();
      this.isCountryChanged = false;
      this.isStatusLoading = false;
    },
    handleVisaStatusChange(){
        this.getVisaApplicationStatusStates();
        this.visaApplicationStatuses = [];
        this.application_visa.visa_status_state_id = null;
        this.isStatusLoading = false;
    },
    getStates() {
      if (this.application_type_index == 0)
        return
      stateService.all(this.application_visa.country_id).then(response => {
        this.states = response.data.data
      })
    },
    getCities() {
      if (this.application_type_index == 0)
        return
      cityService.all(this.application_visa.state_id).then(response => {
        this.cities = response.data.data
      })
    },
    getVisaTypes() {
      if (this.application_type_index == 0)
        return
      visaTypeService.typesByCountryAndApplicationType(this.application_visa.country_id, this.application_visa.application_type_id).then(response => {
        this.visa_types = response.data.visa_types
        this.getVisaApplicationStatus();
      })
    },
    getApplicationTypes() {
      if (this.application_type_index == 0)
        return
      applicationTypeService.all().then(response => {
        this.application_type_list = response.data.application_types
      })
    },
    getNominationSubclass() {
      if (this.application_type_index == 0)
        return
      nominationSubClassService.getAllActive().then(response => {
        this.nomination_sub_classes = response.data.data
      })
    },
    getNominationCategory() {
      if (this.application_type_index == 0)
        return
      nominationCategory.getAllActive().then(response => {
        this.nomination_categories = response.data.data
      })
    },
    getSkillOccupation() {
      if (this.application_type_index == 0)
        return
      skillOccupationService.getByCountry(this.application_visa.country_id).then(response => {
        this.skill_occupations = response.data.occupations
      })
    },
    handleInstitutionChange() {
      this.isInstitutionChanged = true;
      this.educationLevels = [];
      this.getEducationLevels();
      this.programs = [];
      this.programSessions = [];
      this.isInstitutionChanged = false;
      this.getVisaApplicationStatus();
    },
    handleInstitutionLevelChange() {
      this.programs = [];
      this.programSessions = [];
      this.getAcademicProgramSession();
    },
    getDefaultVisaStatus() {
      this.visaApplicationStatuses.map((item) => {
        if (item.is_default == 1) {
          this.application_visa.visa_status_id = item.id;
        }
      })
    },

    handleSessionChange() {
      this.programs = [];
      this.getAcademicPrograms();
    },
    getInstitutions() {
      institutionService
          .getByCountry(this.application_visa.country_id)
          .then((response) => {
            this.institutions = response.data.institutions;
          })
    },
    getAcademicPrograms() {
      program
          .getByInstitution(this.application_visa.institution_id, this.application_visa.education_level_id, this.application_visa.academic_program_session_id)
          .then((response) => {
            this.programs = response.data.programs;
          })
    },
    getAcademicProgramSession() {
      programSession
          .all()
          .then((response) => {
            this.programSessions = response.data.academicProgramSessions;
          })
    },
    saveApplicationVisa() {
      this.application_visa.processed_by = this.currentUser.id;
      this.application_visa.applicant_id = this.$route.params.user_id;
      
      if (this.application_visa.send_email && this.emails.length > 0) {
        this.application_visa.group_email = this.emails;
      }

      this.$v.$touch()
      
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } 
      else {
        this.loading = true;
        if(!this.application_visa.is_ready_to_submit) {
          this.application_visa.is_draft = 1;
        }
        application
            .create(this.application_visa)
            .then((response) => {
              this.$snotify.success('Application saved successfully');
              this.$emit('refresh');
              this.closeDialog();
              this.loading = false;

            })
            .catch(err => {
              this.loading = false;
              this.errors = err.errors;
              this.$snotify.error('Oops looks like something went wrong');
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    getEducationLevels() {
      educationLevel
          .all()
          .then((response) => {
            this.educationLevels = response.data.educationLevels;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    getVisaApplicationStatus() {
      if (!this.application_visa.country_id)
        return
      if (!this.application_visa.application_type)
        return
      this.application_visa.visa_status_id = null
      this.application_visa.visa_status_state_id = null
      this.visa_application_statuses = []
      visaApplicationStatusService.getByCountryAndApplicationType(this.application_visa.country_id, this.application_visa.application_type).then(response => {
        this.visa_application_statuses = response.data.applications_statuses;
      }).catch(error => {
        this.visa_application_statuses = []
      })
    },
    getVisaApplicationStatusStates() {
      if (!this.application_visa.visa_status_id)
        return
      this.visaApplicationStatuses = []
      visaApplicationStatusService
          .getStatusByVisaStatusId(this.application_visa.visa_status_id)
          .then((response) => {
            this.visaApplicationStatuses = response.data.data;
            this.getDefaultVisaStatus();
          })
          .catch(err => {
            this.visaApplicationStatuses = []
          })
          .finally(() => {

          });
    },

    onApplicationTypeSelect(type, index) {
      this.application_type_index = index
      this.application_visa.application_type = type
      this.getVisaApplicationStatus()
    }
  },
}
</script>
