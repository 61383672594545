<template>
  <v-app>
    <v-card raised>
      <v-card-title>
        Employment History
        <v-spacer></v-spacer>
        <v-btn
            class="ma-2 btn btn-primary "
            dark
            small
            @click="manageEmploymentHistory({}, 'open')"
        >
          <v-icon x-small>
            fas fa-plus
          </v-icon>
          Add New
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="table-responsive" style="table-layout: fixed">
          <v-skeleton-loader
              type="table-thead"
              v-if="loading">
          </v-skeleton-loader>

          <v-skeleton-loader
              v-if="loading"
              type="table-row-divider@4">
          </v-skeleton-loader>

          <table v-if="! loading" class="table">
            <thead>
            <th>Organisation</th>
            <th>Designation</th>
            <th>Employment Type</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Action</th>
            </thead>
            <tbody>
            <template v-if="histories && histories.length>0">
              <tr v-for="(history, index) of histories" :key="index">
                <td>{{ history.organisation }}</td>
                <td>{{ history.title }}</td>
                <td>
                  <span class="badge badge-info text-lg`">
                    {{ history.employment_type_text }}
                  </span>
                </td>
                <td>{{ history.formatted_start_from }}</td>
                <td>
                  <span v-if="!history.currently_working">{{ history.formatted_end_date }}</span>
                  <span v-else class="text-success text-uppercase">working</span>
                </td>
                <td>
                  <template>
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a @click="manageEmploymentHistory(history, 'open')" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-edit</v-icon>
                                </span>
                            <span class="navi-text">Edit Employment History</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="deleteEmploymentHistory(history)">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                            <span class="navi-text">Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </template>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="50">No data found</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </v-card-text>
    </v-card>
    <create-or-update v-if="user_id" ref="employment-history" :user_id="user_id" :dialog="history_dialog"
                      @close_dialog="manageEmploymentHistory({}, 'close')"></create-or-update>
  </v-app>
</template>

<script>
import UserEmploymentHistoryService from "@/services/user/employment-history/UserEmploymentHistoryService";
import CreateOrUpdate from "./CreateOrUpdate";

const employmentHistoryService = new UserEmploymentHistoryService();

export default {
  props: ["user_id"],
  components: {
    CreateOrUpdate
  },
  name: "EmploymentHistory",
  data() {
    return {
      histories: [],
      history_dialog: false,
      loading: false
    }
  }, mounted() {
    this.getEmploymentHistory();
  }, methods: {
    getEmploymentHistory() {
      employmentHistoryService.paginate(this.user_id).then(response => {
        this.histories = response.data.data
      })
    }, manageEmploymentHistory(history = {}, type = "open") {
      if (type == "open") {
        this.$refs['employment-history'].assignHistory(history)
        this.history_dialog = true
      } else {
        this.history_dialog = false
        this.getEmploymentHistory();
      }
    },
    deleteEmploymentHistory(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.loading = true
            employmentHistoryService.delete(this.user_id, item.id).then(response => {
              this.$snotify.success("Employment history deleted successfully")
              this.getEmploymentHistory();
            }).catch(error => {
              this.$snotify.error("Something went wrong please try again later")
            }).finally(this.loading = false)
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>