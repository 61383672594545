<template>
    <v-app>
        <v-card raised>
            <v-card-title>
              Educational History
                <v-spacer></v-spacer>
                <v-btn
                        class="ma-2 btn btn-primary "
                        dark
                        small
                        @click="createSchoolingHistory"
                >
                    <v-icon x-small>
                        fas fa-plus
                    </v-icon>
                    Add New
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-expansion-panels v-if="schooling_history.length > 0">
                    <v-expansion-panel
                        v-for="(item,index) in schooling_history" :key="index"
                    >
                        <v-expansion-panel-header>
                          {{item.institution_name}}, {{item.education_level}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="6">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.user}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.primary_language_of_instruction}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                      <v-list-item-content>
                                        <v-list-item-title>Attended To: {{item.formatted_attended_to}}</v-list-item-title>
                                        <v-list-item-subtitle>Attended From: {{item.formatted_attended_from}}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Is Graduated
                                          <span class="badge badge-success text-lg`"
                                                v-bind:class="{ 'badge-success': item.is_graduated, 'badge-danger': !item.is_graduated }"
                                          >{{ item.is_graduated ? 'Yes' : 'No' }}
                                          </span>
                                          <br>
                                          Has Physical Certificate
                                          <span class="mx-5 badge badge-success text-lg`"
                                                       v-bind:class="{ 'badge-success': item.has_physical_certificate, 'badge-danger': !item.has_physical_certificate }"
                                          >{{ item.has_physical_certificate ? 'Yes' : 'No' }}
                                          </span>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.address}}, {{item.city}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.province}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                      <v-list-item-content>
                                        <v-list-item-title>{{item.degree_name}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.formatted_date_of_graduation}}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                      <v-list-item-content>
                                        <v-list-item-title>Grading Scheme: {{item.grading_scheme}}</v-list-item-title>
                                        <v-list-item-title>Grade Average: {{item.grade_average}}</v-list-item-title>
                                        <v-list-item-title>{{item.status_text}}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <div>
                                        <v-btn class="btn btn-primary ml-2" @click="editSchoolingHistory(item)" dark>
                                            Update History
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div v-if="schooling_history.length == 0"
                       class="text-center md:items-center md:justify-between py-12">
                  <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto"  alt=""/>
                  <p>No Academic History available.</p>
                </div>
            </v-card-text>
          <schooling-history ref="schooling-history" @refresh="getAllSchoolingHistory"></schooling-history>
        </v-card>
    </v-app>

</template>

<script>
import SchoolingHistory from "@/view/pages/view/user/student/profile/components/academic-history/SchoolingHistory";
import UserSchoolingHistoryService from "@/services/user/schooling-history/UserSchoolingHistoryService";

const userSchoolingHistory = new UserSchoolingHistoryService();
export default {
  name: "AcademicHistory",
  props: ['user_id'],
  components: {SchoolingHistory},
  data: () => ({
      events: [],
      schooling_history: [],
      input: null,
      userId: '',
      pageCount: null,
      page: null,
      total: null,
      perPage: null,
      nonce: 0,
  }),

  mounted() {
    this.userId = this.$route.params.user_id;
    if(this.userId == undefined) {
      this.userId = this.user_id;
    }
    this.getAllSchoolingHistory();
  },

  computed: {
      timeline () {
          return this.events.slice().reverse()
      },
  },

  methods: {
      comment () {
          const time = (new Date()).toTimeString()
          this.events.push({
              id: this.nonce++,
              text: this.input,
              time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
                  return ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`
              }),
          })

          this.input = null
      },
    getAllSchoolingHistory() {
      userSchoolingHistory.paginate(this.userId, {}, this.page).then(response => {
        this.schooling_history = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.pageCount = response.data.meta.last_page;
      }).catch(() => {})
    },
    createSchoolingHistory() {
        this.$refs['schooling-history'].createSchoolingHistory(this.userId)
    },
    editSchoolingHistory(userSchoolingHistory) {
        this.$refs['schooling-history'].editSchoolingHistory(userSchoolingHistory)
      }
  },
}
</script>

<style scoped>

</style>