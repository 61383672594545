<template>
  <v-dialog persistent
            max-width="800"
            scrollable
            v-model="dialog"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Update application</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">

            <v-col cols="6" v-if="field == 'institutions-program'">
              <v-select
                  v-model="application.institution_id"
                  @change="getAllPrograms"
                  :items="institutions"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Institution <span class="text-danger"></span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="6" v-if="field == 'institutions-program'">
              <v-select
                  :items="education_levels"
                  clearable
                  dense
                  item-text="title"
                  item-value="id"
                  label="Education level"
                  outlined
                  class="custom-select"
                  v-model="application.education_level_id"
              >
                <template v-slot:label>
                  Education level <span class="text-danger">*</span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" v-if="field == 'institutions-program'">
              <v-select
                  v-model="application.academic_program_id"
                  @change="getAllSession"
                  :items="programs"
                  item-text="title"
                  item-value="id"
                  outlined
                  :loading="isProgramLoading"
                  dense
              >
                <template v-slot:label>
                  Program <span class="text-danger"></span>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.title_location"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" v-if="field == 'institutions-program'">
              <v-autocomplete
                  v-model="application.location_id"
                  :items="locations"
                  item-text="full_address"
                  item-value="id"
                  :loading="locationLoading"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Select location <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" v-if="field == 'country'">
              <v-select
                  v-model="application.country_id"
                  :items="countries"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Country <span class="text-danger"></span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" v-if="field == 'sessions'">
              <v-select
                  v-model="application.academic_program_session_id"
                  :items="program_sessions"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Program Session <span class="text-danger"></span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="6" v-if="field == 'deferred'">
              <v-select
                  v-model="application.deferred_session_id"
                  :items="program_sessions"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Deferred Session <span class="text-danger"></span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="6" v-if="field == 'offer'">
              <v-text-field
                  v-model="application.offer_letter_number"
                  type="number"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Offer Letter Number <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="6" v-if="field == 'other' && currentUser && currentUser.access_type == 'head'">
              <v-select
                  outlined
                  dense
                  v-model="application.branch_id"
                  :items="ourBranches"
                  item-text="name"
                  item-value="id"
              >
                <template v-slot:label>Branch<span class="text-danger"></span></template>
              </v-select>
            </v-col>

            <v-col cols="6" v-if="field == 'other'">
              <v-select
                  outlined
                  dense
                  v-model="application.application_status"
                  :items="applicationStatuses"
                  item-text="title"
                  item-value="id"
              >
                <template v-slot:label>Application Status<span class="text-danger"></span></template>
              </v-select>
            </v-col>

            <v-col cols="6" v-if="field == 'other'">
              <v-text-field
                  v-model="application.college_student_number"
                  type="number"
                  outlined
                  dense
              >
                <template v-slot:label>
                  College Student Number <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="6" v-if="field == 'medical'">
              <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  ref="menu"
                  transition="scale-transition"
                  v-model="picker2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      label="Start date"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="application.medical_date"
                  >
                    <template v-slot:label>
                      Medical Date<span class="text-danger"></span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    v-model="application.medical_date"
                    @input = "picker2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" v-if="field == 'deferred'">
              <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  ref="menu"
                  transition="scale-transition"
                  v-model="picker6"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      label="Start date"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="application.deferred_date"
                  >
                    <template v-slot:label>
                      Deferred Date<span class="text-danger"></span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    v-model="application.deferred_date"
                    @input="picker6 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" v-if="field == 'biometric'">
              <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  ref="menu"
                  transition="scale-transition"
                  v-model="picker3"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      label="Start date"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      v-model="application.biometric_date"
                  >
                    <template v-slot:label>
                      Visa Applied Date<span class="text-danger"></span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    @input="picker3 = false"
                    v-model="application.biometric_date"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6"  v-if="field == 'other'">
              <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  ref="menu"
                  transition="scale-transition"
                  v-model="picker4"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      label="Start date"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="application.co_latest_contact_date"
                  >
                    <template v-slot:label>
                      Case Officer Latest Contact Date<span class="text-danger"></span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    v-model="application.co_latest_contact_date"
                    @input="picker4 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" v-if="field == 'visa'">
              <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  ref="menu"
                  transition="scale-transition"
                  v-model="picker5"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      label="Start date"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      v-model="application.visa_decision_date"
                  >
                    <template v-slot:label>
                      Visa Decision Date<span class="text-danger"></span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    :min="today"
                    v-model="application.visa_decision_date"
                    @input="picker5 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3"  v-if="field == 'medical'">
              <v-checkbox v-model="application.medical_submitted" label="Medical Submitted"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'biometric'">
              <v-checkbox v-model="application.biometric_submitted" label="Biometric Submitted"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'other'">
              <v-checkbox v-model="application.is_ready_to_submit" label="Ready For Submit"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'other'">
              <v-checkbox v-model="application.is_ready_for_visa" label="Ready For Visa"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'other'">
              <v-checkbox v-model="application.is_ready_enroll" label="Ready For Enroll"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'other'">
              <v-checkbox v-model="application.is_submitted_school" label="Submitted To Institution"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'visa_decision'">
              <v-checkbox v-model="application.is_enrolment_confirmed" label="Enrollment Confirmed"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'other'">
              <v-checkbox v-model="application.notify_student" label="Notify Student"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'other'">
              <v-checkbox v-model="application.notify_agent" label="Notify Agent"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'other'">
              <v-checkbox v-model="application.is_archived" label="Is Archived"></v-checkbox>
            </v-col>

            <v-col cols="3" v-if="field == 'deferred'">
              <v-checkbox v-model="application.is_deferred" label="Is Deferred"></v-checkbox>
            </v-col>

            <v-col cols="12" v-if="field == 'deferred'">
              <v-textarea v-model="application.deferred_remark" label="Deferred Remark" outlined dense></v-textarea>
            </v-col>

            <v-col cols="3" v-if="field == 'refund'">
              <v-checkbox v-model="application.is_refund" label="Is Refund"></v-checkbox>
            </v-col>

            <v-col cols="9" v-if="field == 'refund'">
              <v-text-field v-model="application.refund_amount" label="Refund Amount" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" v-if="field == 'refund'">
              <v-textarea v-model="application.refund_remark" label="Refund Remark" outlined dense></v-textarea>
            </v-col>

            <v-col cols="6" md="6" v-if="application.application_type!='academic'">
              <v-autocomplete autocomplete="false"
                              outlined
                              dense
                              v-model="application.skill_occupation_id"
                              :items="skill_occupations"
                              item-text="title"
                              item-value="id"
              >
                <template v-slot:label>Skill Occupation <span class="text-danger"></span></template>
              </v-autocomplete>
            </v-col>

            <v-col cols="6" md="6" v-if="application.application_type!='academic'">
              <v-autocomplete autocomplete="false"
                              outlined
                              dense
                              v-model="application.nomination_category_id"
                              :items="nomination_categories"
                              item-text="title"
                              item-value="id"
              >
                <template v-slot:label>Nomination Categories <span class="text-danger"></span></template>
              </v-autocomplete>
            </v-col>

            <v-col cols="6" md="6" v-if="application.application_type!='academic'">
              <v-autocomplete autocomplete="false"
                              outlined
                              dense
                              v-model="application.nomination_sub_class_id"
                              :items="nomination_sub_classes"
                              item-text="title"
                              item-value="id"
              >
                <template v-slot:label>Nomination Sub-class <span class="text-danger"></span></template>
              </v-autocomplete>
            </v-col>

            <v-col cols="6" md="6" v-if="field == 'offer'">
              <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  ref="menu"
                  transition="scale-transition"
                  v-model="picker7"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      label="Start date"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="application.offer_letter_date"
                  >
                    <template v-slot:label>
                      Offer Letter Date<span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    v-model="application.offer_letter_date"
                    @input="picker7 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" md="6" v-if="field == 'offer'">
              <v-checkbox v-model="application.is_offer_letter" label="Offer Letter"></v-checkbox>
            </v-col>

            <v-col cols="6" md="6" v-if="field == 'offer'">
              <v-checkbox v-model="application.is_valid_offer" label="Validate offer letter"></v-checkbox>
            </v-col>

            <v-col cols="12" md="12"  v-if="field == 'status'">
              <label for>Application remarks <span class="text-danger">*</span> </label>
              <ckeditor
                  :config="editorConfig"
                  v-model="application.application_remarks"
              ></ckeditor>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            class="btn btn-primary"
            medium
            @click="updateApplication"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApplicationService from "@/services/application/ApplicationService";
import SkillOccupationService from "@/services/skill-occupation/SkillOccupationService";
import NominationCategoryService from "@/services/nomination/nomination-category/NominationCategoryService";
import NominationSubclassService from "@/services/nomination/nomination-subclass/NominationSubclassService";
import CountryService from "@/services/country/CountryService";
import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import AcademicProgramSessionService from "@/services/academic/program/session/AcademicProgramSessionService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import LocationService from "@/services/academic/institution/location/LocationService";

const skillOccupationService = new SkillOccupationService();
const nominationSubClassService = new NominationSubclassService();
const nominationCategory = new NominationCategoryService();
const applicationService = new ApplicationService();
const country = new CountryService();
const academicProgram = new AcademicProgramService();
const institution = new InstitutionService();
const educationLevel = new EducationLevelService();
const location = new LocationService();

export default {
  name: "UpdateApplication",
  props: ['dialog'],
  data() {
    return {
      field: null,
      countries: [],
      programs: [],
      locations: [],
      institutions: [],
      program_sessions: [],
      skill_occupations: [],
      nomination_categories: [],
      nomination_sub_classes: [],
      education_levels: [],
      editorConfig: {
        versionCheck: false,
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList'],
        ]
      },
      loading: false,
      locationLoading: false,
      picker1: false,
      picker2: false,
      picker3: false,
      picker4: false,
      picker5: false,
      picker6: false,
      picker7: false,
      menuDate: false,
      application: {},
      isProgramLoading: false,
      today: new Date().toISOString().substr(0, 10),
      applicationStatuses: [
        { title: 'Visa not applied', value: 'waiting' },
        { title: 'Visa Applied & Waiting', value: 'visa_applied' },
        { title: 'Visa granted', value: 'granted' },
        { title: 'Visa refused', value: 'rejected' },
      ],
    }
  },
  mounted() {
      this.getAllBranches();
  },
  methods: {
    getAllEducationLevel() {
      educationLevel
          .paginate({})
          .then(response => {
            this.education_levels = response.data.data;
          }).catch((err) => {

      });
    },
    getAllCountry() {
      country.getAllCountry().then(response => {
        this.countries = response.data.data;
      })
          .catch(error => {});
    },
    getAllInstitutions() {
      institution.getByCountry(this.application.country_id).then(response => {
        this.institutions = response.data.institutions;
      })
          .catch(error => {});
    },
    getAllPrograms() {
      this.isProgramLoading = true;
      academicProgram.getByInstitution(this.application.institution_id).then(response => {
        this.programs = response.data.programs;
        this.isProgramLoading = false;
      })
      .catch(error => {
        this.isProgramLoading = false;
      });
    },
    getLocationByInstitutionAndProgram() {
      this.locationLoading = true;
      location
          .getInstitutionAddressForProgram(this.application.institution_id, this.application.academic_program_id)
          .then((response) => {
            this.locations = response.data.data;
            this.locationLoading = false;
          })
          .catch(() => {
            this.locationLoading = false;
          });
    },
    getAllSession() {
      academicProgram.getAcademicProgramSession(this.application.academic_program_id).then(response => {
        this.program_sessions = response.data.programSessions;
        this.getLocationByInstitutionAndProgram();
      }).catch(error => {});
    },
    callApis(application, field) {
      this.application = application;
      this.field = field;
      this.getNominationSubclass();
      this.getNominationCategory();
      this.getSkillOccupation();
      this.getAllCountry();
      this.getAllInstitutions();
      this.getAllEducationLevel();
      this.getAllPrograms();
      this.getAllSession();
      this.getLocationByInstitutionAndProgram();
      if(this.field == 'deferred') {
        this.application.is_deferred = 1;
      }
      if(this.field != 'status') {
        this.application.changed_status = 1;
      }
      if(this.field == 'refund') {
        this.application.is_refund = 1;
        this.application.refund_date = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      }
    },
    closeDialog() {
      this.$emit('close_application_dialog')
      this.field = null;
    },
    getNominationSubclass() {
      if (this.application.application_type != 'visa')
        return
      nominationSubClassService.getAllActive().then(response => {
        this.nomination_sub_classes = response.data.data
      })
    },
    getNominationCategory() {
      if (this.application.application_type != 'visa')
        return
      nominationCategory.getAllActive().then(response => {
        this.nomination_categories = response.data.data
      })
    },
    getSkillOccupation() {
      if (this.application.application_type != 'visa')
        return
      skillOccupationService.getByCountry(this.application.country_id).then(response => {
        this.skill_occupations = response.data.occupations
      })
    },
    updateApplication() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true
            applicationService.update(this.application.id, this.application).then(response => {
              this.$emit('close_application_dialog');
              this.$snotify.success("Update Successfully");
            }).catch(error => {

            }).finally(() => {
              this.loading = false
            })
          }
        }
      });
    }
  }
}
</script>
