<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} English proficiency test score</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                  v-model="testScore.exam_type"
                  :error="$v.testScore.exam_type.$error"
                  @change="resetData"
                  :items="examTypes"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Exam Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.testScore.exam_type.$error"
              >This Exam Type Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menu"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="testScore.exam_date"
                      :error="$v.testScore.exam_date.$error"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Exam Date<span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    :min="currentDate"
                    v-model="testScore.exam_date"
                    no-title
                    @input="menuDate = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.testScore.exam_date.$error">This Exam Date Field is Required.</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menu"
                  v-model="menuExpiryDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="testScore.expiry_date"
                      :error="$v.testScore.expiry_date.$error"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Exam Expiry Date<span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    :min="currentDate"
                    v-model="testScore.expiry_date"
                    no-title
                    @input="menuExpiryDate = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.testScore.expiry_date.$error">This Exam Expiry Date Field is Required.</span>
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type != 'duolingo_english_test' && testScore.exam_type != 'gre' && testScore.exam_type != 'gmat'">
              <v-text-field
                  v-model="testScore.reading"
                  :error="$v.testScore.reading.$error"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Reading <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.reading.$error"
              >This Reading Field is Required</span
              >
              <span class="text-danger" v-if="errors.reading" >* {{ errors.reading[0] }}</span>
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type != 'duolingo_english_test'">
              <v-text-field
                  v-model="testScore.writing"
                  :error="$v.testScore.writing.$error"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Writing <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.writing.$error"
              >This Writing Field is Required</span
              >
              <span class="text-danger" v-if="errors.writing" >* {{ errors.writing[0] }}</span>
            </v-col>


            <v-col cols="12" md="6" v-if="testScore.exam_type == 'gre' || testScore.exam_type == 'gmat'">
              <v-text-field
                  :error="$v.testScore.writing_rank.$error"
                  v-model="testScore.writing_rank"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Writing Rank <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.writing_rank.$error"
              >This Writing Rank Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type != 'duolingo_english_test' && testScore.exam_type != 'gre' && testScore.exam_type != 'gmat'">
              <v-text-field
                  v-model="testScore.speaking"
                  :error="$v.testScore.speaking.$error"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Speaking<span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.speaking.$error">This Speaking Field is required </span>
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type != 'duolingo_english_test' && testScore.exam_type != 'gre' && testScore.exam_type != 'gmat'">
              <v-text-field
                  v-model="testScore.listening"
                  :error="$v.testScore.listening.$error"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Listening <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.listening.$error"
              >This Listening Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type == 'gre' || testScore.exam_type == 'gmat'">
              <v-text-field
                  v-model="testScore.verbal"
                  :error="$v.testScore.verbal.$error"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Verbal <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.verbal.$error"
              >This Verbal Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type == 'gre' || testScore.exam_type == 'gmat'">
              <v-text-field
                  v-model="testScore.verbal_rank"
                  :error="$v.testScore.verbal_rank.$error"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Verbal Rank <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.verbal_rank.$error"
              >This Verbal Rank Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type == 'gre' || testScore.exam_type == 'gmat'">
              <v-text-field
                  v-model="testScore.quantitative"
                  :error="$v.testScore.quantitative.$error"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Quantitative <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.quantitative.$error"
              >This Quantitative Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type == 'gre' || testScore.exam_type == 'gmat'">
              <v-text-field
                  :error="$v.testScore.quantitative_rank.$error"
                  v-model="testScore.quantitative_rank"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Quantitative Rank <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.quantitative_rank.$error"
              >This Quantitative Rank Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type != 'gre'">
              <v-text-field
                  :error="$v.testScore.total.$error"
                  v-model="testScore.total"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Total <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.total.$error"
              >This Total Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6" v-if="testScore.exam_type == 'gmat'">
              <v-text-field
                  :error="$v.testScore.total_rank.$error"
                  v-model="testScore.total_rank"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Total Rank <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.testScore.total_rank.$error"
              >This Total Rank Field is Required</span
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="mr-2" text dark medium @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          {{ edit ? 'Update' : 'Save'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import UserTestScoreService from "@/services/user/test-score/UserTestScoreService";

const userTestScore = new UserTestScoreService();
export default {
  name: "TestScore",
  props: ['user_id'],
  data(){
    return{
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      menuDate: false,
      menuExpiryDate: false,
      errors: [],
      userId: '',
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      examTypes: [
        { name: 'IELTS', value: 'ielts'},
        { name: 'TOEFL', value: 'toefl'},
        { name: 'PTE', value: 'pte'},
        { name: 'Duolingo English Test', value: 'duolingo_english_test'},
        { name: 'GRE', value: 'gre'},
        { name: 'GMAT', value: 'gmat'},
      ],
      testScore: {
        exam_type: '',
        exam_date: '',
        expiry_date: '',
        reading: '',
        writing: '',
        speaking: '',
        listening: '',
        verbal: '',
        verbal_rank: '',
        quantitative: '',
        quantitative_rank: '',
        writing_rank: '',
        total: '',
        total_rank: ''
      },
    }
  },
  validations:{
    testScore:{
      exam_type: {required},
      exam_date: {required},
      expiry_date: {required},
      reading: { required: requiredIf(function () {
          return this.testScore.exam_type != 'duolingo_english_test' && this.testScore.exam_type != 'gre' && this.testScore.exam_type != 'gmat';
        })
      },
      writing: { required: requiredIf(function () {
        return this.testScore.exam_type != 'duolingo_english_test';
        })
      },
      speaking: { required: requiredIf(function () {
          return this.testScore.exam_type != 'duolingo_english_test' && this.testScore.exam_type != 'gre' && this.testScore.exam_type != 'gmat';
        })
      },
      listening: { required: requiredIf(function () {
          return this.testScore.exam_type != 'duolingo_english_test' && this.testScore.exam_type != 'gre' && this.testScore.exam_type != 'gmat';
        })
      },
      verbal: { required: requiredIf(function () {
        return this.testScore.exam_type == 'gre' || this.testScore.exam_type == 'gmat'
        })
      },
      verbal_rank: { required: requiredIf(function () {
          return this.testScore.exam_type == 'gre' || this.testScore.exam_type == 'gmat'
        })
      },
      quantitative: { required: requiredIf(function () {
          return this.testScore.exam_type == 'gre' || this.testScore.exam_type == 'gmat'
        })
      },
      quantitative_rank: { required: requiredIf(function () {
          return this.testScore.exam_type == 'gre' || this.testScore.exam_type == 'gmat'
        })
      },
      writing_rank: { required: requiredIf(function () {
          return this.testScore.exam_type == 'gre' || this.testScore.exam_type == 'gmat'
        })
      },
      total: { required: requiredIf(function () {
         return this.testScore.exam_type != 'gre'
        })
      },
      total_rank: { required: requiredIf(function () {
          return this.testScore.exam_type == 'gmat'
        })
      }
    }
  },
  mounted() {
    this.userId = this.$route.params.user_id;
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    createTestScore(user_id) {
      this.dialog = true;
      this.title = 'Add';
      this.resetForm();
      if(this.userId == undefined) {
        this.userId = user_id;
      }
    },
    editTestScore(item) {
      this.dialog = true;
      this.edit = true;
      this.title = 'Edit';
      this.testScore = item;
    },
    resetData() {
      if(this.testScores.exam_type == 'duolingo_english_test') {
        this.testScores.reading = '';
        this.testScores.writing = '';
        this.testScores.speaking = '';
        this.testScores.listening = '';
        this.testScores.verbal = '';
        this.testScores.verbal_rank = '';
        this.testScores.quantitative = '';
        this.testScores.quantitative_rank = '';
        this.testScores.writing_rank = '';
        this.testScores.total_rank = '';
      }
      if(this.testScores.exam_type == 'gmat' || this.testScores.exam_type == 'gre') {
        this.testScores.reading = '';
        this.testScores.writing = '';
        this.testScores.speaking = '';
        this.testScores.listening = '';
      }
      if(this.testScores.exam_type == 'ielts' || this.testScores.exam_type == 'toefl' ||  this.testScores.exam_type == 'pte') {
        this.testScores.verbal = '';
        this.testScores.verbal_rank = '';
        this.testScores.quantitative = '';
        this.testScores.quantitative_rank = '';
        this.testScores.writing_rank = '';
        this.testScores.total_rank = '';
      }
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      userTestScore
          .create(this.userId, this.testScore)
          .then(response => {
            this.$snotify.success(`Test Score Created Successfully`);
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.loading = false;
          })
    },
    update: function () {
      this.loading = true;
      userTestScore
          .update(this.userId, this.testScore.id, this.testScore)
          .then(response => {
            this.$snotify.success(`Test Score Update Successfully`);
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.loading = false;
          })
    },
    resetForm() {
      this.testScore = {
        exam_type: '',
        exam_date: '',
        expiry_date: '',
        reading: '',
        writing: '',
        speaking: '',
        listening: '',
        verbal: '',
        verbal_rank: '',
        quantitative: '',
        quantitative_rank: '',
        writing_rank: '',
        total: '',
        total_rank: ''
      };
    },
  },
}
</script>

<style scoped>

</style>