<template>
  <v-app>
    <v-card raised>
      <v-card-title>
        Family Member
        <v-spacer></v-spacer>
        <v-btn
            class="ma-2 btn btn-primary "
            dark
            small
            @click="manageFamilyMember({}, 'open')"
        >
          <v-icon x-small>
            fas fa-plus
          </v-icon>
          Add New
        </v-btn>
      </v-card-title>

      <v-card-text>
        <template v-if="loading">
          <v-skeleton-loader
              type="table-thead"
          >
          </v-skeleton-loader>

          <v-skeleton-loader
              type="table-row-divider@4">
          </v-skeleton-loader>
        </template>
        <template v-else>
          <table class="table">
            <thead>
            <th>Name</th>
            <th>Address</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Relation</th>
<!--            <th>Emergency Contact</th>-->
            <th>Action</th>
            </thead>
            <tbody>
            <template v-if="members && members.length>0">
              <tr v-for="(mem, index) of members" :key="index">
                <td>{{ mem.full_name }}</td>
                <td>{{ mem.address }}</td>
                <td>{{ mem.email ? mem.email : 'N/A' }}</td>
                <td>{{ mem.phone }}</td>
                <td class="text-uppercase"><span class="badge badge-primary">{{ mem.relation_type }}</span></td>
<!--                <td class="text-uppercase"><span class="badge badge-primary" v-bind:class="{'badge-primary': mem.is_emergency_contact, 'badge-danger': !mem.is_emergency_contact }">{{ mem.is_emergency_contact ? 'Yes' : 'No' }}</span></td>-->
                <td>
                  <template>
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-item" v-if="!mem.is_emergency_contact">
                          <a @click="makeEmergencyContact(mem.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-phone</v-icon>
                                </span>
                            <span class="navi-text">Make Emergency Contact</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a @click="manageFamilyMember(mem, 'open')" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-edit</v-icon>
                                </span>
                            <span class="navi-text">Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="deleteFamilyMember(mem.id)">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                            <span class="navi-text">Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </template>
                </td>
              </tr>
            </template>
            <template v-else>
              No data found
            </template>
            </tbody>
          </table>
        </template>
      </v-card-text>
    </v-card>
    <create-and-update v-if="user_id && family_member_dialog" :dialog="family_member_dialog" :user_id="user_id"
                       :member="member"
                       @refresh="manageFamilyMember({},'close')"></create-and-update>
  </v-app>
</template>

<script>

import CreateAndUpdate from "./CreateAndUpdate";
import UserFamilyMemberService from "@/services/user/family-member/UserFamilyMemberService";

const familyMemberService = new UserFamilyMemberService();

export default {
  name: "Index",
  props: ['user_id'],
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      members: [],
      member: {
        user_id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        phone: null,
        address: null,
        relation_type: null,
        spoken_language: [],
      },
      loading: false,
      family_member_dialog: false,
      page: null,
      total: null,
      perPage: null,
    }
  }, mounted() {
    this.getAllFamilyMember();
  },
  methods: {
    manageFamilyMember(item, type = 'open') {
      if (type == 'open') {
        if (item.id)
          this.member = item
        else
          this.resetForm()
        this.family_member_dialog = true
      } else {
        this.family_member_dialog = false
        this.resetForm()
        this.getAllFamilyMember();
      }
    }, getAllFamilyMember() {
      this.loading = true
      familyMemberService.paginate(this.user_id).then(response => {
        this.members = response.data.data
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    }, deleteFamilyMember(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true
            familyMemberService
                .delete(this.user_id, id)
                .then((response) => {
                  this.getAllFamilyMember()
                  this.displayMessage('success');
                  this.loading = false
                })
                .catch((err) => {
                  this.displayMessage('error');
                  this.loading = false
                });
          }
        },
      });
    },
    makeEmergencyContact(id) {
      this.$confirm({
        message: `Are you sure to make emergency contact? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true
            familyMemberService
                .updateEmergencyContact(this.user_id, id)
                .then((response) => {
                  this.getAllFamilyMember()
                  this.$snotify.success('Emergency Contact Success')
                  this.loading = false
                })
                .catch((err) => {
                  this.displayMessage('error');
                  this.loading = false
                });
          }
        },
      });
    },
    resetForm() {
      this.member = {
        user_id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        phone: null,
        address: null,
        relation_type: null,
        spoken_language: [],
      }
    }
  }
}
</script>

<style scoped>

</style>