<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Educational History</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                  v-model="schoolingHistory.country_id"
                  :error="$v.schoolingHistory.country_id.$error"
                  @change="selectGrading"
                  :items="countries"
                  item-text="title"
                  item-value="id"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Country <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.schoolingHistory.country_id.$error"
              >This Country Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  label="Education Level"
                  v-model="schoolingHistory.education_level_id"
                  :error="$v.schoolingHistory.education_level_id.$error"
                  :items="education_levels"
                  item-text="title"
                  item-value="id"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                Education Level <span class="text-danger">*</span>
              </template>
              </v-select>
              <span class="text-danger" v-if="$v.schoolingHistory.education_level_id.$error"
              >This Education LevelField is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  label="Grading Level"
                  v-model="schoolingHistory.grading_scheme_id"
                  :error="$v.schoolingHistory.grading_scheme_id.$error"
                  :items="grading_schemes"
                  item-text="title"
                  item-value="id"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Grading Scheme <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.schoolingHistory.grading_scheme_id.$error"
              >This Grading Scheme Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  label="Grading Average"
                  v-model="schoolingHistory.grade_average"
                  :error="$v.schoolingHistory.grade_average.$error"
                  type="number"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Grading Average <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.schoolingHistory.grade_average.$error"
              >This Grading Average Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  label="Education Level"
                  v-model="schoolingHistory.status"
                  :error="$v.schoolingHistory.status.$error"
                  :items="statusTypes"
                  item-text="name"
                  item-value="value"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Status <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.schoolingHistory.status.$error"
              >This Status Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="schoolingHistory.institution_name"
                  :error="$v.schoolingHistory.institution_name.$error"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Institution Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.schoolingHistory.institution_name.$error"
              >This Institution Name Field is Required</span
              >
              <span class="text-danger" v-if="errors.institution_name" >* {{ errors.institution_name[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="schoolingHistory.primary_language_of_instruction"
                  :error="$v.schoolingHistory.primary_language_of_instruction.$error"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Primary Language Of Instruction <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.schoolingHistory.primary_language_of_instruction.$error"
              >This Primary Language Of Instruction Field is Required</span
              >
              <span class="text-danger" v-if="errors.primary_language_of_instruction" >* {{ errors.primary_language_of_instruction[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menu"
                  v-model="menuFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="schoolingHistory.attended_from"
                      :error="$v.schoolingHistory.attended_from.$error"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Attended From <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="schoolingHistory.attended_from"
                    outlined
                    dense
                    no-title
                    @input="menuFrom = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.schoolingHistory.attended_from.$error">Attended From Field is Required.</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menu"
                  v-model="menuTo"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="schoolingHistory.attended_to"
                      :error="$v.schoolingHistory.attended_to.$error"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Attended To <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    :min="schoolingHistory.attended_from"
                    v-model="schoolingHistory.attended_to"
                    outlined
                    dense
                    no-title
                    @input="menuTo = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.schoolingHistory.attended_to.$error">This Attended To Field is Required.</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menu"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="schoolingHistory.date_of_graduation"
                      :error="$v.schoolingHistory.date_of_graduation.$error"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Date of Graduation<span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="schoolingHistory.date_of_graduation"
                    outlined
                    dense
                    no-title
                    @input="menuDate = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.schoolingHistory.date_of_graduation.$error">This Date of Graduation Field is Required.</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="schoolingHistory.degree_name"
                  :error="$v.schoolingHistory.degree_name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Degree Name<span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.schoolingHistory.degree_name.$error">This Degree Name Field is required </span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="schoolingHistory.address"
                  :error="$v.schoolingHistory.address.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Address <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.schoolingHistory.address.$error"
              >This Address Field is Required</span
              >
            </v-col>

<!--            <v-col cols="12" md="6">-->
<!--              <v-text-field-->
<!--                  v-model="schoolingHistory.city"-->
<!--                  :error="$v.schoolingHistory.city.$error"-->
<!--                  outlined-->
<!--                  dense-->
<!--              >-->
<!--                <template v-slot:label>-->
<!--                  City <span class="text-danger">*</span>-->
<!--                </template>-->
<!--              </v-text-field>-->
<!--              <span class="text-danger" v-if="$v.schoolingHistory.city.$error"-->
<!--              >This City Field is Required</span-->
<!--              >-->
<!--            </v-col>-->

<!--            <v-col cols="12" md="6">-->
<!--              <v-text-field-->
<!--                  v-model="schoolingHistory.province"-->
<!--                  :error="$v.schoolingHistory.province.$error"-->
<!--                  outlined-->
<!--                  dense-->
<!--              >-->
<!--                <template v-slot:label>-->
<!--                  Province <span class="text-danger">*</span>-->
<!--                </template>-->
<!--              </v-text-field>-->
<!--              <span class="text-danger" v-if="$v.schoolingHistory.province.$error"-->
<!--              >This Province Field is Required</span-->
<!--              >-->
<!--            </v-col>-->

<!--            <v-col cols="12" md="6">-->
<!--              <v-text-field-->
<!--                  v-model="schoolingHistory.postal_code"-->
<!--                  :error="$v.schoolingHistory.postal_code.$error"-->
<!--                  type="number"-->
<!--                  outlined-->
<!--                  dense-->
<!--              >-->
<!--                <template v-slot:label>-->
<!--                  Postal Code <span class="text-danger">*</span>-->
<!--                </template>-->
<!--              </v-text-field>-->
<!--              <span class="text-danger" v-if="$v.schoolingHistory.postal_code.$error"-->
<!--              >This Postal Code Field is Required</span-->
<!--              >-->
<!--            </v-col>-->

            <v-col cols="12" md="4" >
              Is Graduated
              <v-switch
                  v-model="schoolingHistory.is_graduated"
                  :label="schoolingHistory.is_graduated ? 'Yes' : 'No'"
                  hide-details
              ></v-switch>
            </v-col>

            <v-col cols="12" md="4" >
              Has Physical Certificate
              <v-switch
                  v-model="schoolingHistory.has_physical_certificate"
                  :label="schoolingHistory.has_physical_certificate ? 'Yes' : 'No'"
                  hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="mr-2" text dark
            medium @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required } from "vuelidate/lib/validators";
import UserSchoolingHistoryService from "@/services/user/schooling-history/UserSchoolingHistoryService";
import CountryService from "@/services/country/CountryService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import GradingSchemeService from "@/services/grading-scheme/GradingSchemeService";

const userSchoolingHistory = new UserSchoolingHistoryService();
const country = new CountryService();
const educationLevel = new EducationLevelService();
const gradingScheme = new GradingSchemeService();
export default {
  name: "SchoolingHistory",
  data(){
    return{
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      menuDate: false,
      menuFrom: false,
      menuTo: false,
      errors: [],
      countries: [],
      education_levels: [],
      grading_schemes: [],
      userId: '',
      statusTypes: [
        { name: 'OnGoing', value: 'ongoing'},
        { name: 'Completed', value: 'completed'},
      ],
      schoolingHistory: {
        user_id: '',
        country_id: '',
        education_level_id: '',
        grading_scheme_id: '',
        institution_name: '',
        primary_language_of_instruction: '',
        attended_from: '',
        attended_to: '',
        degree_name: '',
        date_of_graduation: '',
        address: '',
        city: '',
        province: '',
        postal_code: '',
        grade_average: '',
        status: '',
        is_graduated: '',
        has_physical_certificate:'',
      },
    }
  },
  validations:{
    schoolingHistory:{
      country_id:{required},
      education_level_id:{required},
      grading_scheme_id:{required},
      grade_average:{required},
      status:{required},
      institution_name: {required},
      attended_from: {required},
      attended_to: {required},
      degree_name: {required},
      date_of_graduation: {required},
      primary_language_of_instruction: {required},
      address: {required},
      city: {required},
      province: {required},
      postal_code: {required}
    }
  },
  mounted() {
    this.userId = this.$route.params.user_id;
    this.getAllCountries();
    this.getAllEducationLevels();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    createSchoolingHistory(userId) {
      this.dialog = true;
      this.title = 'Add';
      if(this.userId == undefined) {
        this.userId = userId;
      }
      this.resetForm();
    },
    editSchoolingHistory(item) {
      this.dialog = true;
      this.edit = true;
      this.title = 'Edit';
      this.schoolingHistory = item;
      this.selectGrading();
    },
    getAllGradingSchemes(countryId) {
      gradingScheme.getByCountry(countryId).then(response => {
        this.grading_schemes = response.data.grading_schemes;
      }).catch(() => {})
    },
    getAllCountries() {
      country.getAllCountry({}).then(response => {
        this.countries = response.data.data;
      }).catch(() => {})
    },
    getAllEducationLevels() {
      educationLevel.all().then(response => {
        this.education_levels = response.data.educationLevels;
      }).catch(() => {})
    },
    selectGrading() {
      this.getAllGradingSchemes(this.schoolingHistory.country_id);
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      userSchoolingHistory
          .create(this.userId, this.schoolingHistory)
          .then(response => {
            this.$snotify.success(`Schooling History Created Successfully`);
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.loading = false;
          })
    },
    update: function () {
      this.loading = true;
      userSchoolingHistory
          .update(this.userId, this.schoolingHistory.id, this.schoolingHistory)
          .then(response => {
            this.$snotify.success(`Schooling History Update Successfully`);
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.loading = false;
          })
    },
    resetForm() {
      this.schoolingHistory = {
        user_id: '',
        country_id: '',
        education_level_id: '',
        institution_name: '',
        primary_language_of_instruction: '',
        attended_from: '',
        attended_to: '',
        degree_name: '',
        date_of_graduation: '',
        address: '',
        city: '',
        province: '',
        postal_code: '',
        is_graduated: '',
        has_physical_certificate:'',
      };
    },
  },
}
</script>

<style scoped>

</style>