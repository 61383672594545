import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class TaskChecklistService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/task';
    }

    paginate(task_id,data = {}, index = null) {
        let url = `${this.#api}/${task_id}/checklist`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    all(task_id) {
        let url = `${this.#api}/${task_id}/checklist/get/all`
        return apiService.get(url)
    }

    show(task_id,id) {
        let url = `${this.#api}/${task_id}/checklist/${id}`
        return apiService.get(url)
    }

    create(task_id,data) {
        let url = `${this.#api}/${task_id}/checklist`
        return apiService.post(url, data)
    }

    update(task_id,id, data) {
        let url = `${this.#api}/${task_id}/checklist/${id}`
        return apiService.put(url, data)
    }


    delete(examOptionId) {
        let url = `${this.#api}/${examOptionId}`
        return apiService.delete(url)
    }

    saveCropperImage(id, data) {
        let url = `${this.#api}/${id}/save-image`
        return apiService.post(url, data)
    }
}
