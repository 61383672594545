<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>User Documents</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ User Document
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="openSuggestRequiredDocument" class="mt-4 mr-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Suggest User Document
                  </v-btn>
                  <v-btn @click="openCustomizeRequiredDocument" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Customize User Document
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive" style="table-layout: fixed">
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="px-3">
                  <th><strong>File Name</strong></th>
                  <th><strong>Document Type</strong></th>
                  <th><strong> Type</strong></th>
                  <th><strong>Submitted</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="documents.length > 0" v-for="item in documents">
                    <td>
                      <a href="#" @click.prevent="openSubmittedDocument(item)" target="_blank"
                         class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ item.title }}
                      </a>
                    </td>
                    <td>
                      <span class="text-uppercase">{{ item.document_type }}</span>
                    </td>
                    <td>
                      <span class="text-uppercase">{{ item.type }}</span>
                    </td>
                    <td class="">
                      <span class="fas" :class="item.is_submitted?'text-success  fa-check':'text-danger fa-ban'"></span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editDialog(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click.prevent="deleteUserDocument(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="documents.length == 0">
                    <td colspan="2" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="documents.length > 0"
                  class="pull-right mt-7"
                  @input="getAllDocumentation"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="suggestDialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Suggest Required Documentation</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select chips
                          v-model="required_documents"
                          :items="document_list" item-value="id" item-text="title"
                          label="Required Documents"
                          multiple dense outlined>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggle">
                      <v-list-item-action>
                        <v-icon :color="required_documents.length > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                </v-select>
              </v-col>
            </v-row>
            <v-row>

              <!--              <v-col md="12" v-for="(suggest,index) in suggested_document">-->
              <!--                <v-row>-->
              <!--                  <v-col md="3">-->
              <!--                    <span class="text-dark-75 font-size-lg">{{suggest}}</span>-->
              <!--                  </v-col>-->
              <!--                  <v-col md="8">-->
              <!--                  <v-file-input dense outlined-->
              <!--                                v-model="suggest_data[suggest]"-->
              <!--                                prepend-inner-icon="mdi-file"-->
              <!--                                prepend-icon="" :label="'Add Document'">-->

              <!--                  </v-file-input>-->
              <!--                </v-col>-->
              <!--                  <v-col md="1">-->
              <!--                    <a @click="deleteSuggestedDocumentRow(index,suggest)" href="#" class="mr-2 remove-cart-wrapper">-->
              <!--                      <i  class="fas fa-times" style="color: red"></i>-->
              <!--                    </a>-->
              <!--                  </v-col>-->
              <!--                </v-row>-->
              <!--              </v-col>-->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              @click="closeSuggestRequiredDocument"
          >
            Cancel
          </v-btn>

          <v-btn
              class="btn btn-primary"
              @click="saveDocument()"
          >
            Request Document
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="customizeDialog"
        width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Customize Document </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field dense outlined v-model="customize.title" :label="'File Title'"
                              :error="$v.customize.title.$error">
                </v-text-field>
                <span class="text-danger" v-if="$v.customize.title.$error">This field is required </span>
              </v-col>
              <v-col cols="12">
                <v-select :items="document_types"
                          outlined dense
                          v-model="customize.document_type"
                          item-text="title"
                          item-value="value"
                          label="Document Type" :error="$v.customize.document_type.$error"
                ></v-select>
                <span class="text-danger" v-if="$v.customize.document_type.$error">This field is required </span>
              </v-col>
              <v-col cols="12">
                <v-select :items="types"
                          outlined dense
                          v-model="customize.type"
                          item-text="title"
                          item-value="value"
                          label="Use For" :error="$v.customize.type.$error"
                ></v-select>
                <span class="text-danger" v-if="$v.customize.type.$error">This field is required </span>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-switch v-model="customize.is_required" label="Required"></v-switch>
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <v-file-input dense outlined
                              v-model="customize.file"
                              prepend-inner-icon="mdi-file"
                              prepend-icon="" :label="'Add Document'">

                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              @click="closeCustomizeRequiredDocument">
            Cancel
          </v-btn>
          <v-btn
              class="btn btn-primary"
              @click="saveCustomize()"
          >
            Add Document
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="updateDialog"
        width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Document </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-text-field dense outlined v-model="editData.title" :label="'File Title'">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <v-file-input dense outlined
                              v-model="editData.file"
                              prepend-inner-icon="mdi-file"
                              prepend-icon="" :label="'Add Document'">

                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              @click="updateDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              class="btn btn-primary"
              @click="updateDocument()"
          >
            Add Document
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="400" v-model="document_view_dialog">
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          {{ document_content }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="document_view_dialog=false;document_content=null">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>

</template>
<script>
import UserDocumentService from "@/services/user/document/UserDocumentService";
import RequiredDocumentService from "@/services/required-document/RequiredDocumentService";
import {required} from 'vuelidate/lib/validators';

const requiredDocument = new RequiredDocumentService();
const userDocumentation = new UserDocumentService();
export default {
  name: "ApplicantManager",
  validations: {
    customize: {
      title: {required},
      document_type: {required},
      type: {required},
    }
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: 'File Name',
          align: 'start',
          sortable: false,
          value: 'title',
        },

        {text: 'Action', value: 'action'},
      ],
      total: null,
      perPage: null,
      page: null,
      documents: [],
      suggestDialog: false,
      customizeDialog: false,
      updateDialog: false,
      required_documents: [],
      document_list: [],
      suggested_document: [],
      suggest_data: [],
      customize: {
        title: '',
        file: null,
        user_id: '',
        document_type: null,
        type: null
      },
      editData: {
        title: '',
        file: null,
        user_id: '',
      },
      types: [
        {title: "File", value: "file"},
        {title: "Text", value: "text"}
      ],
      document_types: [
        {title: "Academic", value: "academic"},
        {title: "Visa", value: "visa"}
      ],
      document_view_dialog: false,
      document_content: null,
    }
  }
  ,
  methods: {
    getAllDocumentation() {
      userDocumentation
          .paginate(this.user_id, this.search, this.page)
          .then(response => {
            this.documents = response.data.data;
          })
    }
    ,
    openSuggestRequiredDocument() {
      this.suggestDialog = true;
      this.getAllRequiredDocument();
    }
    ,
    editDialog(item) {
      this.updateDialog = true;
      this.editData = item;
    }
    ,
    closeSuggestRequiredDocument() {
      this.suggestDialog = false;
      this.suggested_document = [];
      this.required_documents = [];
      this.suggest_data = [];
    }
    ,
    openCustomizeRequiredDocument() {
      this.customizeDialog = true;
    }
    ,
    closeCustomizeRequiredDocument() {
      this.customizeDialog = false;
      this.customize = {
        title: '',
        file: null,
        user_id: '',
      }
    }
    ,
    closeUpdateRequiredDocument() {
      this.updateDialog = false;
      this.editData = {
        title: '',
        file: null,
        user_id: '',
      }
    }
    ,
    getAllRequiredDocument() {
      this.document_list = [];
      requiredDocument
          .getAllActive()
          .then(response => {
            this.document_list = response.data.documents;
          })
    }
    ,
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllRequiredDocument) {
          this.required_documents = []
        } else {

          this.required_documents = this.document_list.map(function (list) {
            return list.title;
          }).slice();
        }
      })
    }
    ,
    addDocument() {

      this.required_documents.forEach(document => {
        let obj = null;
        if (this.suggested_document && this.suggested_document.length > 0) {
          let index = this.suggested_document.findIndex(val => val == document);
          obj = this.suggested_document[index];
        }
        if (obj == undefined || obj == null) {
          this.suggested_document.push(document);
        } else {

        }
      });
    }
    ,
    deleteSuggestedDocumentRow(index) {
      this.suggested_document.splice(index, 1);
      this.required_documents.splice(index, 1);
    }
    ,
    saveDocument() {

      let data = {'suggested_document': this.required_documents}
      userDocumentation
          .create(this.user_id, data)
          .then(response => {
            this.$snotify.success("Document Requested Successfully");
            this.getAllDocumentation();
            this.required_documents = [];
            this.suggestDialog = false;
          })
          .catch(error => {
            this.errors = error.errors;
          });
    }
    ,
    updateDocument() {
      let formData = new FormData();
      for (let key in this.editData) {
        if (key === "file" && this.editData[key] != null && this.editData[key] != undefined) {
          formData.append('file', this.editData[key]);
        } else {
          if (this.editData[key]) {
            formData.append(key, this.editData[key]);
          }
        }
      }
      userDocumentation
          .update(this.user_id, this.editData.id, formData)
          .then(response => {
            this.$snotify.success("Document Added Succefully");
            this.closeUpdateRequiredDocument();
            this.getAllDocumentation();
          })
          .catch(error => {
            this.errors = error.errors;
          });
    }
    ,
    convertToFormDataForCustomize() {
      let formData = new FormData();
      for (let key in this.customize) {
        if (key === "file" && this.customize[key] != null && this.customize[key] != undefined) {
          formData.append('file', this.customize[key]);
        } else {
          if (this.customize[key]) {
            formData.append(key, this.customize[key] == true ? 1 : this.customize[key]);
          }
        }
      }
      return formData;
    }
    ,
    saveCustomize() {
      this.$v.customize.$touch()
      if (this.$v.customize.$error) {
        setTimeout(() => {
          this.$v.customize.$reset()
        }, 3000)
        return
      }
      let formData = this.convertToFormDataForCustomize();
      userDocumentation
          .create(this.user_id, formData)
          .then(response => {
            this.$snotify.success("Document Added Succefully");
            this.closeCustomizeRequiredDocument();
            this.getAllDocumentation();
          })
          .catch(error => {
            this.errors = error.errors;
          });
    }
    ,

    deleteUserDocument(itemId) {

      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            userDocumentation
                .delete(this.user_id, itemId)
                .then((response) => {
                  this.getAllDocumentation();
                  this.$snotify.success("User Document Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    openSubmittedDocument(item) {
      if (item.document_type == 'file' && item.file_path.real) {
        window.open(item.file_path.real, "_blank")
      } else {
        this.document_view_dialog = true
        this.document_content = item.description
      }
    },
  }
  ,
  mounted() {
    this.getAllDocumentation();
  }
  ,
  computed: {
    user_id() {
      return this.$route.params.user_id;
    }
    ,
    likesAllRequiredDocument() {
      return this.required_documents.length === this.document_list.length
    }
    ,
    likesSomeRequiredDocument() {
      return this.required_documents.length > 0 && !this.likesAllRequiredDocument
    }
    ,
    icon() {
      if (this.likesAllRequiredDocument) return 'mdi-close-box'
      if (this.likesSomeRequiredDocument) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
    ,
  }
}
</script>

<style scoped>

</style>