<template>
  <v-app>
    <v-card raised>
      <v-card-title>
        Applications
        <v-spacer></v-spacer>
        <v-btn
            @click="createVisaApplication"
            class="ma-2 btn btn-primary "
            dark
            small
            v-if="!submitted_application"
        >
          <v-icon x-small>
            fas fa-plus
          </v-icon>
          Start Application
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel
              :key="index" focusable
              v-for="(item, index) in applications"
              v-if="applications.length != 0"
          >
            <v-expansion-panel-header v-if="item.application_type =='academic'"
                                      @click.once="getStatusHistories(item.id)"
            >
              {{ item.institution }} - {{ item.academic_program }} 
              

              <div v-if="item.is_ready_to_submit">
                <v-chip class="ml-3" color="primary">Application Submitted</v-chip>
              </div>
              <div v-if="item.is_deferred">
                <v-chip class="ml-3" color="warning">Deferred</v-chip>
              </div>
              <div v-if="item.is_refund">
                <v-chip  class="ml-3" color="warning">Refund</v-chip>
              </div>

              <div v-if="item.agent_id">
                <v-chip  class="ml-3 text-white" style="cursor:pointer;" @click="viewApplicationChanges(item.id)" small color="red">Recently Updated by Agent</v-chip>
              </div>

            </v-expansion-panel-header>
            <v-expansion-panel-header v-else
                                      @click.once="getStatusHistories(item.id)"
            >
              {{ item.application_type_title }} - {{ item.application_permit }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12">
                  <v-btn @click="gotToApplication(item.id)" color="primary" small dark class="float-right pull-right"><v-icon class="mr-3" x-small>fas fa-eye</v-icon> Application details</v-btn>
                </v-col>
              </v-row>
             <application-history :histories="histories" :item="item" @update-list="getVisaApplications"></application-history>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div v-if="applications.length == 0">
          <v-col class="text-center md:items-center md:justify-between py-12">
            <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto"/>
            <p>No processing has started for this user.</p>
            <v-btn @click="createVisaApplication" class="btn btn-secondary" v-if="!submitted_application">
              Start processing
            </v-btn>
          </v-col>
        </div>

        <b-pagination
            v-if="applications.length > 0"
            class="pull-right mt-7"
            @input="getVisaApplications"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
        ></b-pagination>
      </v-card-text>
    </v-card>
    <create-and-update-visa
        @refresh="getVisaApplications"
        ref="create-and-update-visa-application"
    ></create-and-update-visa>

    <application-log
      ref="get-application-log"
    ></application-log>
  </v-app>

</template>

<script>
import ApplicationService from "@/services/application/ApplicationService";
import ApplicationHistoryService from "@/services/application/history/ApplicationHistoryService";
import ApplicationHistory from "@/view/pages/view/user/student/profile/components/application/ApplicationHistory";
import CreateAndUpdateVisa from "@/view/pages/view/user/student/profile/components/application/CreateAndUpdateVisa";
import ApplicationLog from "./ApplicationLog";

const application = new ApplicationService();
const applicationHistory = new ApplicationHistoryService();

export default {
  name: "visa",
  components: {
    CreateAndUpdateVisa,
    ApplicationHistory,
    ApplicationLog
  },
  data: () => ({
    events: [],
    search: {},
    applications: [],
    submitted_application: false,
    input: null,
    nonce: 0,
    total: null,
    perPage: null,
    page: null,
    user_id: null,
    histories: [],
    application: {},
    applicationLog: '',
  }),

  computed: {
    timeline() {
      return this.events.slice().reverse()
    },
  },

  methods: {
    gotToApplication(id) {
      this.$router.push({
        name: 'application-detail',
        params: { id: id }
      });
    },
    comment() {
      const time = (new Date()).toTimeString()
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
          return ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`
        }),
      })

      this.input = null
    },

    createVisaApplication() {
      this.$refs['create-and-update-visa-application'].createVisaApplication(this.user_id)
    },
    getVisaApplications() {
      application
          .paginate(this.search, this.page, this.user_id)
          .then((response) => {
            this.applications = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
    },
    getSubmittedApplication() {
      application
          .getSubmittedApplication(this.user_id)
          .then((response) => {
            this.submitted_application = response.data.submitted_application;
          })
    },
    getStatusHistories(applicationId) {
      application
          .getHistories(applicationId)
          .then((response) => {
            this.histories = response.data.data;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    updateStatus(application, histories) {
      let visaApplicationStatusId = histories[0].visa_status_id
      this.$refs['update-application-history'].updateApplicationHistory(application, visaApplicationStatusId);
    },
    updateApplication(item) {
      this.application_dialog = true
      this.application = item
      this.$refs['application-update'].callApis(item)
    },
    viewApplicationChanges(applicationId){
      application
      .getChangeLog(applicationId)
      .then((res) => {
        this.applicationLog = res.data.data;
        this.$refs['get-application-log'].openDialog(this.applicationLog);
      })
      .catch(err => {
        this.$snotify.error('Unfortunately we could not show any changes.');
      })
    }
  },
  mounted() {
    this.user_id = this.$route.params.user_id;
    this.getVisaApplications();
    this.getSubmittedApplication();
  }
}
</script>

<style scoped>

</style>