import { render, staticRenderFns } from "./EducationHistory.vue?vue&type=template&id=314c3aa7&scoped=true&"
import script from "./EducationHistory.vue?vue&type=script&lang=js&"
export * from "./EducationHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314c3aa7",
  null
  
)

export default component.exports