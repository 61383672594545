<template>
  <v-app>
    <div class="main" style="background-color: #f3f6f9">
      <div>
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex mb-9">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">

                <v-avatar
                    class="profile"
                    color="grey"
                    size="100"
                    tile
                >
                  <v-img :src=" user.image_path['thumb']" v-if="user.image_path"></v-img>
                  <img
                      v-else
                      :alt="user.full_name"
                      :src="`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${user.full_name}`"
                  >
                </v-avatar>
                <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                  <span class="font-size-h3 symbol-label font-weight-boldest">JM</span>
                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                       href="#">{{ user.full_name }}</a>
                    <a href="#">
                      <i class="flaticon2-correct text-success font-size-h5"></i>
                    </a>
                  </div>
                </div>
                <!--end::Title-->
                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-new-email mr-2 font-size-lg"></i>{{ user.email }}</a>
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-user-outline-symbol mr-2 font-size-lg"></i>{{ user.gender_text }}</a>
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-phone mr-2 font-size-lg"></i>{{ user.phone || user.mobile }}</a>
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-calendar mr-2 font-size-lg"></i>{{ user.formatted_date_of_birth }}</a>
                    </div>
                    <!--end::Pic-->
                    <!--begin::Info-->
<!--                    <div class="flex-grow-1">-->
<!--                      &lt;!&ndash;begin::Content&ndash;&gt;-->
<!--                      <div class="d-flex flex-wrap justify-content-between mt-1">-->
<!--                        &lt;!&ndash;                                <div class="d-flex flex-column flex-grow-1 pr-8">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                    <span class="font-weight-bold text-dark-50">I distinguish three main text objectives could be merely to inform people.</span>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                    <span class="font-weight-bold text-dark-50">A second could be persuade people.You want people to bay objective</span>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                </div>&ndash;&gt;-->
<!--                        <div class="d-flex align-items-center w-25 flex-fill float-right  ">-->
<!--                          <span class="font-weight-bold text-dark-75">Progress</span>-->
<!--                          <div class="progress progress-xs mx-3 w-100">-->
<!--                            <div aria-valuemax="100" aria-valuemin="0"-->
<!--                                 aria-valuenow="50" class="progress-bar bg-success"-->
<!--                                 role="progressbar"-->
<!--                                 style="width: 63%;"></div>-->
<!--                          </div>-->
<!--                          <span class="font-weight-bolder text-dark">78%</span>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;end::Content&ndash;&gt;-->
<!--                    </div>-->
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
            <div class="separator separator-solid"></div>

          </div>
        </div>
      </div>
      <v-row>
        <v-col cols="4" lg="3" xl="2" md="3" sm="3">
          <v-tabs
              v-model="tab"
              vertical>
            <v-tab v-if="checkIsAccessible('user', 'show')">
              <v-icon left>
                mdi-account
              </v-icon>
              Summary
            </v-tab>
            <v-tab v-if="checkIsAccessible('user', 'show')">
              <v-icon left>
                fas fa-info
              </v-icon>
              Details
            </v-tab>
            <v-tab v-if="checkIsAccessible('user_address', 'show')">
              <v-icon left>
                fas fa-address-book
              </v-icon>
              Address
            </v-tab>
            <v-tab v-if="checkIsAccessible('appointment', 'show')">
              <v-icon left>
                fas fa-business-time

              </v-icon>
              Appointments
            </v-tab>
            <v-tab v-if="checkIsAccessible('application', 'show')">
              <v-icon left>
                fas fa-passport
              </v-icon>
              Applications
            </v-tab>
<!--            <v-tab v-if="checkIsAccessible('user-payment', 'show')">-->
<!--              <v-icon left>-->
<!--                mdi-cash-->
<!--              </v-icon>-->
<!--              Payments-->
<!--            </v-tab>-->
            <v-tab v-if="checkIsAccessible('user-academics', 'show')">
              <v-icon left>
                fas fa-school
              </v-icon>
              {{currentUser.view_path == 'applycan' ? "Academics" : "Education History"}}
            </v-tab>
            <v-tab v-if="checkIsAccessible('user-test-score', 'show')">
              <v-icon left>
                fas fa-vote-yea
              </v-icon>
              {{currentUser.view_path == 'applycan' ? "English Test" : "Test Score"}}
            </v-tab>

            <v-tab v-if="checkIsAccessible('user-document', 'show')">
              <v-icon left>
                fas fa-file
              </v-icon>
              Documents
            </v-tab>

            <v-tab v-if="checkIsAccessible('user-employment-history', 'show')">
              <v-icon left>
                fas fa-address-book
              </v-icon>
              Employment History
            </v-tab>

            <v-tab v-if="checkIsAccessible('user-family-member', 'show')">
              <v-icon left>
                fas fa-users
              </v-icon>
              Family Member
            </v-tab>

            <v-tab v-if="checkIsAccessible('user-applicant-manager', 'show')">
              <v-icon left>
                fas fa-user-tie
              </v-icon>
              Counsellor
            </v-tab>

            <v-tab v-if="checkIsAccessible('branch', 'show')">
              <v-icon left>
                fas fa-sitemap
              </v-icon>
              Branch
            </v-tab>

            <v-tab v-if="checkIsAccessible('task-manager', 'show')">
              <v-icon left>
                fas fa-tasks
              </v-icon>
              Tasks
            </v-tab>

            <v-tab v-if="checkIsAccessible('application-discussion', 'show')">
              <v-icon left>
                fas fa-comment-alt
              </v-icon>
              Application discussion
            </v-tab>

            <v-tab v-if="checkIsAccessible('agent-limit-institution', 'list')">
              <v-icon left>
                fas fa-users
              </v-icon>
              Limit Institution
            </v-tab>

            <v-tab v-if="checkIsAccessible('application-discussion', 'show')">
              <v-icon left>
                fas fa-tags
              </v-icon>
              Support
            </v-tab>

            <v-tab v-if="checkIsAccessible('user-email', 'show')">
              <v-icon left>
                fas fa-envelope-open-text
              </v-icon>
              Email History
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="8" lg="9" xl="10" md="9" sm="9">
          <v-tabs-items v-model="tab">

            <v-tab-item v-if="checkIsAccessible('user', 'show')">
              <account-summary
                  :managers="managers"
                  :upcoming_appointment="upcoming_appointment"
                  :application="application"
                  :agent="agent"
                  :user="user"
                  :application_histories="application_histories"
                  @refresh="_detail"
              ></account-summary>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user', 'show')">
              <user-details
                  :user_id="user.id"
                  :user="user"
                  :user_setting="user_setting"
                  @setting-saved="getUserSetting"
                  @user-saved="_detail"
              ></user-details>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user_address', 'show')">
              <user-address></user-address>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('appointment', 'show')"
            >
              <follow-up-appointment
                :user_id="user.id"
                @apointment-saved="_detail"
              ></follow-up-appointment>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('application', 'show')"
            >
              <visa></visa>
            </v-tab-item>

<!--            <v-tab-item v-if="checkIsAccessible('user-payment', 'show')"-->
<!--            >-->
<!--              <PaymentList v-if="user.id" :user_id="user.id"></PaymentList>-->
<!--            </v-tab-item>-->

            <v-tab-item v-if="checkIsAccessible('user-academics', 'show')"
            >
              <academic-history></academic-history>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-test-score', 'show')"
            >
              <user-test-score></user-test-score>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-document', 'show')">
              <user-document></user-document>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-employment-history', 'show')">
              <employment-history v-if="user_id" :user_id="user_id"></employment-history>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-employment-history', 'show')">
              <family-member v-if="user_id" :user_id="user_id"></family-member>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-applicant-manager', 'show')">
              <applicant-manager
                  :user_id="user.id"
              ></applicant-manager>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('branch', 'show')">
              <user-branch :user_id="user.id"></user-branch>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('task-manager', 'show')">
              <task-list :user_id="user.id"></task-list>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('application-discussion', 'show')">
              <application-discussion :user_id="user.id" ></application-discussion>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('agent-limit-institution', 'list')">
              <student-limit-institution></student-limit-institution>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('application-discussion', 'show')">
              <support :user_id="user.id"></support>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-email', 'show')">
              <email-activity v-if="user.id" :user_id="user.id"></email-activity>
            </v-tab-item>

          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import UserApplicantManagerService from "@/services/user/applicant-manager/UserApplicantManagerService";
import UserDetails from "./components/UserDetails";
import FollowUpAppointment from "./components/FollowUpAppointment";
import ApplicantManager from "./components/ApplicantManager";
import PaymentList from "./components/payment/PaymentList";
import Visa from "./components/Visa";
import AcademicHistory from "./components/AcademicHistory";
import UserDocument from "./components/UserDocument";
import UserBranch from "./components/UserBranch";
import AccountSummary from "./components/AccountSummary";
import UserAddress from "./components/UserAddress";
import UserService from "@/services/user/UserService";


import UserSettingService from "@/services/user/setting/UserSettingService";
import UserTestScore from "@/view/pages/view/user/student/profile/components/UserTestScore";
import UserEducationHistory from "@/view/pages/view/user/student/profile/components/UserEducationHistory";
import EmailActivity from "@/view/pages/view/user/student/profile/components/EmailActivity";
import ApplicationService from "@/services/application/ApplicationService";
import EmploymentHistory from "@/view/pages/view/user/student/profile/components/employment-history/EmploymentHistory";
import FamilyMember from  "@/view/pages/view/user/student/profile/components/family-member/Index";

const user = new UserService();
const userSetting = new UserSettingService();
const userApplicationManager = new UserApplicantManagerService();
const application = new ApplicationService();

import TaskList from "./components/task/TaskList";
import ApplicationDiscussion from "@/view/pages/view/user/student/profile/components/application-discussion/ApplicationDiscussion";
import ShowAllDiscussionClosed from "@/view/pages/view/user/student/profile/components/application-discussion/ShowAllDiscussionClosed";
import Support from "@/view/pages/view/user/student/profile/components/application-discussion/support/Support";
import StudentLimitInstitution from "@/view/pages/view/user/student/profile/components/limit-institution/StudentLimitInstitution";

export default {
  name: "Index",
  components: {
    StudentLimitInstitution,
    Support,
    ShowAllDiscussionClosed,
    ApplicationDiscussion,
    EmailActivity,
    UserEducationHistory,
    UserDetails,
    FollowUpAppointment,
    ApplicantManager,
    Visa,
    UserTestScore,
    UserDocument,
    AcademicHistory,
    AccountSummary,
    PaymentList,
    UserAddress,
    UserBranch,
    EmploymentHistory,
    TaskList,
    FamilyMember,
  },
  data() {
    return {
      tab: 0,
      user_id: null,
      user: '',
      user_setting: {},
      search: {
        type: 'today'
      },
      total: null,
      perPage: null,
      page: null,
      appointments: [],
      totalCounts: [],
      agent: '',
      managers: [],
      upcoming_appointment: '',
      application: '',
      application_histories: '',
    }
  },
  methods: {
    getApplicantManagers() {
      userApplicationManager
          .all(this.user_id)
          .then((response) => {
            this.managers = response.data.data;
          })
          .catch((err) => {

          });
    },
    _detail() {
      if (this.user_id != null || this.user_id != undefined) {
        user
            .show(this.user_id)
            .then(response => {
              this.user = response.data.user;
              if(this.user && this.user.added_by) {
                this.getUserAgent();
              } else {
                this.agent = null
              }
            })
            .catch(err => {

            })
            .finally(() => {

            });
      }
    },
    getUserSetting() {
      userSetting
          .index(this.user_id)
          .then(response => {
            this.user_setting = response.data.user_setting;
            if (this.user_setting == null) {
              this.user_setting = {
                user: null,
                country_of_citizen_id: '',
                first_language: '',
                passport_number: '',
                passport_expiry_date: '',
                is_married: '',
                refused_visa: false,
                valid_study_permit: '',
                details: '',
              }
            }
          })
    },
    getUpcomingAppointment() {
      user
          .getUpcomingAppointment(this.user_id)
          .then((response) => {
            this.upcoming_appointment = response.data.data;
          })
    },

    getLatestApplication() {
      application
          .getLatest(this.user_id)
          .then((response) => {
            this.application = response.data.application;
            this.application_histories = response.data.histories;
          });
    },
    getUserAgent(){
      user
      .getUserAgent(this.user.added_by)
      .then((response) => {
         this.agent = response.data.agent;
      })
      .catch(() => {
          this.agent = {};
      });
    },
  },
  mounted() {
    this.user_id = this.$route.params.user_id;
    this._detail();
    this.getUserSetting();
    this.getApplicantManagers();
    this.getUpcomingAppointment();
    this.getLatestApplication();
  }
}
</script>

<style scoped>

</style>
