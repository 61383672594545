import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class TaskService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/task';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    all() {
        let url = `${this.#api}/get/all`
        return apiService.get(url)
    }
    getTasksByApplicant(user_id) {
        let url = `${this.#api}/get/applicant/${user_id}`
        return apiService.get(url)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.post(url, data)
    }

    delete(examOptionId) {
        let url = `${this.#api}/${examOptionId}`
        return apiService.delete(url)
    }

    saveCropperImage(id, data) {
        let url = `${this.#api}/${id}/save-image`
        return apiService.post(url, data)
    }
    countApplyBy()
    {
        let url = `${this.#api}/assigned_by/count`;
        return apiService.get(url)
    }
    countApplyTo()
    {
        let url = `${this.#api}/assigned_to/count`;
        return apiService.get(url)
    }
}
