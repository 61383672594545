<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
        scrollable
        persistent
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title class="text-h5">
                <span>{{ title }} Application Counsellor</span>
                <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-select
                                outlined
                                dense
                                v-model="applicant_manager.admin_user_id"
                                :items="admin_users"
                                item-text="full_name"
                                item-value="id"
                                :error="$v.applicant_manager.admin_user_id.$error"
                            >
                                <template v-slot:label>
                                    Select Applicant Manager
                                    <span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.applicant_manager.admin_user_id.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.admin_user_id" >* {{ errors.admin_user_id[0] }}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="mr-2" text dark medium
                    @click="closeDialog">
                Cancel
                </v-btn>
                <v-btn
                    class="btn btn-primary"
                    medium
                    @click="saveApplicantManager()"
                    :loading="loading"
                >
                Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AdminUserService from "@/services/admin-user/AdminUserService";
import UserApplicantManagerService from "@/services/user/applicant-manager/UserApplicantManagerService";
import {required} from "vuelidate/lib/validators";

const adminUser = new AdminUserService();
const userApplicationManager = new UserApplicantManagerService();

export default {
    validations:{
        applicant_manager: {
            admin_user_id: {required}
        }
    },
    data(){
        return{
            dialog: false,
            title: '',
            loading: false,
            admin_users : [],
            applicant_manager: {
                admin_user_id: null,
                user_id: null,
            },
            errors: []
        }
    },
    methods:{
        createApplicantManager(user_id){
            this.applicant_manager.user_id = user_id;
            this.getAdminUsers();
            this.dialog = true;
            this.title = 'Create';
        },
        getAdminUsers(){
            userApplicationManager
            .getAdminUsers(this.applicant_manager.user_id)
            .then((response) => {
                this.admin_users = response.data;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        closeDialog(){
            this.resetForm();
            this.dialog = false;
        },
        saveApplicantManager(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset()
                }, 3000);
            }
            else {
                this.loading = true;
                userApplicationManager
                .create(this.applicant_manager.user_id, this.applicant_manager)
                .then((response) => {
                    this.$snotify.success('Succesfully added applicant manager');
                    this.loading = false;
                    this.$emit('refresh');
                    this.closeDialog();
                })
                .catch((err) => {
                    this.errors = err.errors;
                    this.loading = false;
                    this.$snotify.error('Oops looks like something went wrong');
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        },
        resetForm(){
            this.applicant_manager = {
                admin_user_id: null,
                user_id: null,
            },
            this.errors = [];
            this.$v.$reset();
        }
    }
}
</script>
