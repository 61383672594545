<template>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title>Application log</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="logs.length > 0" cols="12">
              <ul>
                <li style="font-size: 14px;" v-for="log in logs">
                    <strong> {{ log.field }} </strong> changed from <b> {{ log.original }} </b> to <b> {{ log.changed }} </b>
                </li>
              </ul> 
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="mr-2" text dark medium @click="closeDialog" depressed>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {    
    data() {
      return {
        dialog: false,
        logs: '',
      }
    },
    methods:{
        openDialog(logs){
            this.dialog = true;
            this.logs = logs;
        },
        closeDialog(){
            this.dialog = false;
        }
    }
  }
  </script>
  
  <style scoped>
  
  </style>