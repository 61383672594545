<template>
  <v-app>
    <v-card raised>
      <v-card-title>
         Education History
        <v-spacer></v-spacer>
        <v-btn
            class="ma-2 btn btn-primary "
            dark
            small
            @click="createEducationHistory"
        >
          <v-icon x-small>
            fas fa-plus
          </v-icon>
          Add New
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="educationHistory"
            class="elevation-1"
            hide-default-footer
        >
          <template v-slot:item.action="{ item }">
            <v-icon
                small
                color="blue"
                @click="editEducationHistory(item)"
            >
              fas fa-pen
            </v-icon>
            <v-icon
                class="mx-3"
                small
                color="red"
                @click="deleteEducationHistory(item.id)"
            >
              fas fa-trash
            </v-icon>
          </template>

          <template v-slot:item.start_date="{ item }">
            {{item.formatted_start_date ? item.formatted_start_date : '--'}}
          </template>

          <template v-slot:item.end_date="{ item }">
            {{item.formatted_end_date ? item.formatted_end_date : '--'}}
          </template>
          <v-row>
            <v-col cols="12">
              <v-pagination
                  v-if="this.educationHistory.length>0"
                  v-model="page"
                  @input="getAllUserEducationHistory"
                  :length="pageCount"
                  :total-visible="7"
                  :total-rows="total"
                  :per-page="perPage"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-data-table>
      </v-card-text>
      <education-history ref="education-history" @refresh="getAllUserEducationHistory"></education-history>
    </v-card>
  </v-app>
</template>

<script>
import EducationHistory from "@/view/pages/view/user/student/profile/components/education-history/EducationHistory";
import UserEducationHistoryService from "@/services/user/education-history/UserEducationHistoryService";

const userEducationHistory = new UserEducationHistoryService();
export default {
  name: "UserEducationHistory",
  components: {EducationHistory},
  data() {
    return {
      userId: '',
      educationHistory: [],
      pageCount: null,
      page: null,
      total: null,
      perPage: null,
      headers: [
        {
          text: 'User Name',
          align: 'start',
          sortable: false,
          value: 'user',
        },
        {text: 'Country Of Education', value: 'country_of_education'},
        {text: 'Education Level', value: 'education_level'},
        {text: 'Grading Scheme', value: 'grading_scheme'},
        {text: 'Start Date', value: 'start_date'},
        {text: 'End Date', value: 'end_date'},
        {text: 'Status', value: 'status'},
        {text: 'Action', value: 'action'},
      ]
    }
  },
  mounted() {
    this.userId = this.$route.params.user_id;
    this.getAllUserEducationHistory();
  },
  methods: {
    createEducationHistory() {
      this.$refs['education-history'].createEducationHistory();
    },
    editEducationHistory(item) {
      this.$refs['education-history'].editEducationHistory(item);
    },
    getAllUserEducationHistory() {
      userEducationHistory.paginate(this.userId,{}, this.page).then(response => {
          this.educationHistory = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.pageCount = response.data.meta.last_page;
      }).catch(() => {});
    },
    deleteEducationHistory(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            userEducationHistory
                .delete(this.userId, id)
                .then((response) => {
                  this.getAllUserEducationHistory()
                  this.$snotify.success("User Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>

<style scoped>

</style>