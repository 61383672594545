<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
      persistent
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Application Progress Update</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                  v-if="application.visa_decision_date"
                  v-model="application.application_status"
                  :items="applicationStatus"
                  item-text="name"
                  item-value="value"
                  :error="$v.application.application_status.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Application Status <span class="text-danger">*</span>
                </template>
              </v-select>
              <h3 v-else class="text-danger">Please first update visa decision date</h3>
              <span class="text-danger" v-if="$v.application.application_status.$error">This field is required</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-file-input
                  v-model="application.file"
                  prepend-icon=""
                  prepend-inner-icon="fas fa-file"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Letter attachment <span class="text-danger"></span>
                </template>
              </v-file-input>
<!--              <span class="text-danger" v-if="$v.application.application_status.$error">This information is required</span>-->
            </v-col>


            <v-col cols="12" md="12">
              <v-text-field
                  v-model="application.visa_length"
                  type="number"
                  :error="$v.application.visa_length.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Visa length (Month) <span class="text-danger">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <label for>Description <span class="text-danger">*</span> </label>
              <ckeditor
                  :config="editorConfig"
                  v-model="application.application_remarks"
              ></ckeditor>
              <span class="text-danger" v-if="$v.application.application_remarks.$error">This field is required</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-checkbox
                  v-model="application.send_mail"
                  label="Send email upon any changes"
              ></v-checkbox>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-if="checkIsAccessible('application', 'edit')"
            class="btn btn-primary"
            dark
            medium
            @click="createOrUpdate()"
            :loading="loading"
            :disabled="application.visa_decision_date == null"
        >
          Update
        </v-btn>
        <v-btn
            color="mr-2" text dark medium
            @click="closeDialog">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import ApplicationService from "@/services/application/ApplicationService";

const applicationService = new ApplicationService();
export default {
  name: "ApplicationStatus",
  data() {
    return{
      loading: false,
      dialog: false,
      application: {
        application_status: '',
        file: null,
        application_remarks: '',
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']],
      },
      applicationStatus: [
        {name: "Granted", value: 'granted'},
        {name: "Rejected", value: 'rejected'}
      ]
    }
  },
  validations: {
    application: {
      application_status: { required },
      visa_length: { required },
      application_remarks: { required },
    }
  },
  methods: {
    openDialog(application) {
      this.dialog = true;
      this.resetForm();
      this.application = {
        id: application.id,
        visa_decision_date: application.visa_decision_date,
        application_status: application.application_status,
        file: null,
        application_remarks: application.application_remarks,
        send_mail: false
      };
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this. application = {
        application_status: '',
        file: null,
        application_remarks: ''
      };
    },
    convertFormData() {
      let formData = new FormData();
      for (let key in this.application) {
        if (key === "file" && this.application[key] != null && this.application[key] != undefined) {
          formData.append('file', this.application[key]);
        }else {
          if(this.application[key]){
            formData.append(key, this.application[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.update();
      }
    },
    update: function () {
      this.loading = true;
      let formData = this.convertFormData();
      applicationService
          .updateApplicationStatus(this.application.id, formData)
          .then((response) => {
            this.$snotify.success("Application Status Updated Successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
  }
}
</script>

<style scoped>

</style>
