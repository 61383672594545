<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        Update Institute and Program
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete autocomplete="false"
                            outlined
                            dense
                            v-model="application.institution_id"
                            :items="institutions"
                            item-text="title"
                            item-value="id"
                            :error="$v.application.institution_id.$error"
                            @change="handleInstitutionChange"
            >
              <template v-slot:label>Institution <span class="text-danger">*</span></template>
            </v-autocomplete>
            <span class="text-danger" v-if="$v.application.institution_id.$error">This Field is required</span>
            <!--            <span class="text-danger" v-if="errors.institution_id">{{ errors.institution_id[0] }}</span>-->
          </v-col>
          <v-col cols="12">
            <v-autocomplete autocomplete="false"
                            outlined
                            dense
                            v-model="application.academic_program_id"
                            :items="programs"
                            item-text="title"
                            :error="$v.application.academic_program_id.$error"
                            item-value="id"
            >
              <template v-slot:label>Program <span class="text-danger">*</span></template>
            </v-autocomplete>
            <span class="text-danger"
                  v-if="$v.application.academic_program_id.$error">This Field is required</span>
            <!--            <span class="text-danger" v-if="errors.academic_program_id">{{ errors.academic_program_id[0] }}</span>-->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="resetForm"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            class="btn btn-primary"
            medium
            @click="updateApplication"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import ApplicationService from "@/services/application/ApplicationService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import AcademicProgramService from "@/services/academic/program/AcademicProgramService";

const applicationService = new ApplicationService();
const institutionService = new InstitutionService();
const programService = new AcademicProgramService();

export default {
  name: "UpdateInstitutionAndProgram",
  props: ['dialog'],
  validations: {
    application: {
      institution_id: {required},
      academic_program_id: {required}
    }
  }, data() {
    return {
      loading: false,
      application: {},
      institutions: [],
      programs: [],
    }
  }, mounted() {
    this.getInstitution();
  }, methods: {
    callApis(application) {
      this.application = application;
      this.getInstitutions()
    },
    handleInstitutionChange(){
      this.application.academic_program_id =null;
      this.getAcademicPrograms();
    },
    getInstitutions() {
      institutionService
          .getByCountry(this.application.country_id)
          .then((response) => {
            this.institutions = response.data.institutions;
            this.getAcademicPrograms();
          })
    },
    getAcademicPrograms() {
      programService
          .getByInstitution(this.application.institution_id, this.application.education_level_id)
          .then((response) => {
            this.programs = response.data.programs;
          })
    }, validateForm() {
      this.$v.application.$touch();
      if (this.$v.application.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
        return false
      } else {
        return true;
      }
    },
    updateApplication() {
      if (!this.validateForm())
        return
      this.$confirm({
        message: `Are you sure you want to change the institution and program?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let data = {
              institution_id: this.application.institution_id,
              academic_program_id: this.application.academic_program_id,
              country_id: this.application.country_id,
              education_level_id: this.application.education_level_id,
            }
            this.loading = true;
            applicationService.update(this.application.id, data).then(response => {
              this.displayMessage('success')
              this.resetForm();
            }).catch(err => {
              this.loading = false;
              this.displayMessage('error')
            })
          }
        }
      });

    },
    resetForm() {
      this.loading = false;
      this.$emit('refresh');
    }
  }
}
</script>

<style scoped>

</style>