<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title>
        {{ title }} Family Member
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field outlined dense label="First Name" v-model="member.first_name"
                          :error="$v.member.first_name.$error">
              <template v-slot:label>First Name <span class="text-danger">*</span></template>
            </v-text-field>
            <span class="text-danger" v-if="$v.member.first_name.$error">This field is required</span>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined dense label="Middle Name" v-model="member.middle_name">
              <template v-slot:label>Middle Name</template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined dense label="Last Name" v-model="member.last_name"
                          :error="$v.member.last_name.$error">
              <template v-slot:label>Last Name <span class="text-danger">*</span></template>
            </v-text-field>
            <span class="text-danger" v-if="$v.member.last_name.$error">This field is required</span>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined dense label="Email" v-model="member.email" :error="$v.member.email.$error">
              <template v-slot:label>Email<span class="text-danger">*</span></template>
            </v-text-field>
            <span class="text-danger" v-if="$v.member.email.$error">This field is required</span>
            <span class="text-danger" v-if="!$v.member.email.email">This Email should be valid</span>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined dense label="Phone" v-model="member.phone" :error="$v.member.phone.$error">
              <template v-slot:label>Phone<span class="text-danger">*</span></template>
            </v-text-field>
            <span class="text-danger" v-if="$v.member.phone.$error">This field is required</span>
          </v-col>
          <v-col cols="12" md="6">
            <v-select outlined dense :items="relations" item-value="value" item-text="title"
                      v-model="member.relation_type"
                      :error="$v.member.relation_type.$error">
              <template v-slot:label>Relation Ship<span class="text-danger">*</span></template>
            </v-select>
            <span class="text-danger" v-if="$v.member.relation_type.$error">This field is required</span>
          </v-col>
          <v-col cols="12">
            <v-combobox
                v-model="member.spoken_language"
                label="Spoken Language"
                outlined
                small-chips
                multiple
                dense
            >
            </v-combobox>
          </v-col>
          <v-col cols="12">
            <v-textarea outlined dense label="Address" v-model="member.address" :error="$v.member.address.$error">
              <template v-slot:label>Address<span class="text-danger">*</span></template>
            </v-textarea>
            <span class="text-danger" v-if="$v.member.address.$error">This field is required</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark medium
            @click="resetForm">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
            v-if="checkIsAccessible('user-family-member', 'create') || checkIsAccessible('user-family-member', 'edit')"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserFamilyMemberService from "@/services/user/family-member/UserFamilyMemberService";
import {required, email} from "vuelidate/lib/validators";

const familyMemberService = new UserFamilyMemberService();

export default {
  name: "CreateAndUpdate",
  props: ['user_id', 'dialog', 'member'],
  validations: {
    member: {
      first_name: {required},
      last_name: {required},
      email: {required, email},
      phone: {required},
      address: {required},
      relation_type: {required},
    }
  },
  data() {
    return {
      loading: null,
      relations: [
        {title: 'Father', value: 'father'},
        {title: 'Mother', value: 'mother'},
        {title: 'Spouse', value: 'spouse'},
        {title: 'Child', value: 'child'},
        {title: 'Friends', value: 'friends'},
        {title: 'Other', value: 'other'},
      ]
    }
  }, computed: {
    title() {
      let title = "Create";
      if (this.member.id)
        title = "Update"
      return title
    }
  }, methods: {
    resetForm() {
      this.$v.member.$reset();
      this.$emit('refresh')
    },
    createOrUpdate() {
      this.$v.member.$touch();
      if (this.$v.member.$error) {
        setTimeout(() => {
          this.$v.member.$reset();
        }, 3000)
      } else {
        if (this.member.id) {
          this.update();
        } else {
          this.create();
        }
      }
    },
    update() {
      this.loading = true
      familyMemberService.update(this.user_id, this.member.id, this.member).then(response => {
        this.$snotify.success("Family member create successfully");
        this.resetForm();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later");
      }).finally(this.loading = false)
    },
    create() {
      this.loading = true
      familyMemberService.create(this.user_id, this.member).then(response => {
        this.$snotify.success("Family member successfully");
        this.resetForm();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later");
      }).finally(this.loading = false)
    },
  }
}
</script>
