<template>
    <v-app>
        <v-dialog max-width="900" scrollable v-model="dialog">
            <v-card>
                <v-card-title class="d-flex justify-content-between">
                    <v-sheet @click="editedTask.title =!editedTask.title" v-if="!editedTask.title">
                        {{task.title}} <v-btn icon>
                        <v-icon x-small>
                            fas fa-edit
                        </v-icon>
                    </v-btn>
                    </v-sheet>
                    <v-text-field @focusout="editedTask.title =!editedTask.title" dense outlined v-else
                                  v-model="task.title">

                    </v-text-field>
                    <span class="text-danger" v-if="$v.task.title.$error">Title is Required</span>



                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="9">
                            <div class="text-subtitle-2 mb-2">Members</div>
                            <div class="my-2">

                                <v-btn @click="editedTask.assigned_to =!editedTask.assigned_to" color="blue" dark fab
                                       rounded
                                       x-small>
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                                <v-autocomplete
                                        :items="admin_users"
                                        :loading="isBusy"
                                        :search-input.sync="searchName"
                                        clearable
                                        deletable-chips
                                        small-chips
                                        dense
                                        item-text="full_name"
                                        item-value="id"
                                        label="Assign To User CRN or Email"
                                        multiple
                                        outlined
                                        class="mt-2"
                                        v-model="assigned_to"
                                >
                                    <!--                                    <template slot="selection" slot-scope="data">-->
                                    <!--                                        &lt;!&ndash; HTML that describe how select should render selected items &ndash;&gt;-->
                                    <!--                                        {{ data.item.personal_id }} - {{ data.item.full_name }}- {{ data.item.email }}-->
                                    <!--                                    </template>-->
                                    <!--                                    <template slot="item" slot-scope="data">-->
                                    <!--                                        &lt;!&ndash; HTML that describe how select should render items when the select is open &ndash;&gt;-->
                                    <!--                                        {{ data.item.personal_id }} - {{ data.item.full_name }}- {{ data.item.email }}-->
                                    <!--                                    </template>-->
                                </v-autocomplete>

                            </div>
                            <div class="my-2">
                                <span class="font-weight-bold text-subtitle-1">Description</span>
                                <v-btn @click="editedTask.description =!editedTask.description" class="px-2 ml-2"
                                       depressed
                                       small>Edit
                                </v-btn>

                                <div>
                                    <v-sheet @click="editedTask.description =!editedTask.description"
                                             v-if="!editedTask.description">
                                        <p class="ml-4" v-html="task.description"></p>
                                    </v-sheet>

                                    <ckeditor :config="editorConfig"
                                              @focusout="editedTask.description =!editedTask.description"
                                              class="mt-2" v-if="editedTask.description"
                                              v-model="task.description"></ckeditor>
                                    <span class="text-danger"
                                          v-if="$v.task.description.$error">Description is Required</span>

                                </div>

                                <!--                                <div class="my-2" v-if="task.id">-->
                                <!--                                         <span class="font-weight-bold text-subtitle-1">-->
                                <!--                                       <v-icon>-->
                                <!--                                       fas fa-check-square-->
                                <!--                                    </v-icon>-->
                                <!--                                    Attachments</span>-->
                                <!--                                    <v-btn @click="addChecklist =true" class="px-2 ml-2"-->
                                <!--                                           depressed-->
                                <!--                                           small>Add-->
                                <!--                                    </v-btn>-->
                                <!--                                    <v-sheet></v-sheet>-->
                                <!--                                </div>-->
                                <div class="my-2" v-if="task.id">
                                         <span class="font-weight-bold text-subtitle-1">
                                       <v-icon>
                                       fas fa-check-square
                                    </v-icon>
                                    Checklist</span>
                                    <v-btn @click="addChecklist =true" class="px-2 ml-2"
                                           depressed
                                           small>Add
                                    </v-btn>
                                </div>
                                <v-text-field @click:append="__addList" @keyup.enter="__addList"
                                              append-icon="fas fa-user-edit" class="my-2"
                                              dense
                                              outlined
                                              v-if="addChecklist"
                                              v-model="checklistData.checklist">

                                </v-text-field>
                                <div v-if="checklists.length">

                                    <v-progress-linear dark
                                                       height="10"
                                                       v-model="completed_percentage"
                                    >
                                        <strong> {{completed_percentage.toFixed(2)}}%</strong>
                                    </v-progress-linear>
                                </div>
                                <v-list dense flat v-if="checklists.length">
                                    <v-list-item-group

                                            color="primary"
                                    >
                                        <v-list-item
                                                :key="i"
                                                v-for="(item, i) in checklists"
                                        >
                                            <v-list-item-icon>
                                                <v-checkbox @change="toogleChecklist(item)"
                                                            v-model="item.is_complete"></v-checkbox>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title
                                                        :class="item.is_complete ? 'text-decoration-line-through' : '' "
                                                        v-text="item.checklist"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                <br>
                                <span class="font-weight-bold text-subtitle-1">

                                    Comments</span>
                                <template v-if="comments.length">
                                    <div :key="index"
                                         class="d-flex justify-content-start mb-2 align-items-center"
                                         v-for="(comment,index) in comments">
                                        <v-avatar class="mx-1" size="30">
                                            <img
                                                    :alt="comment.added_by"
                                                    :src="`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${comment.added_by}`"
                                            >
                                        </v-avatar>

                                        <span>
                                            <strong>
                                                {{comment.added_by}}
                                            </strong>
                                            <br>
                                            <span>
                                            {{ comment.posted_at | moment("from", "now") }}
                                        </span>

                                        </span>

                                        <p class="m-0 ml-3">
                                            {{comment.comment}}
                                        </p>

                                    </div>
                                </template>

                                <div class="d-flex justify-content-between">

                                    <v-avatar class="mx-1" size="30">
                                        <img
                                                :alt="currentUser.full_name"
                                                :src="`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${currentUser.full_name}`"
                                        >
                                    </v-avatar>

                                    <v-text-field @click:append="__addComment()" @keyup.enter="__addComment()"
                                                  append-icon="fas fa-user-edit" dense hint="Press Enter to post"
                                                  outlined
                                                  persistent-hint
                                                  placeholder="Press Enter to post" v-model="commentData.comment">

                                    </v-text-field>

                                </div>


                            </div>
                        </v-col>
                        <v-col cols="3">
                            <div class="text-subtitle-2 font-weight-bold mb-2 text-h2">Status</div>
                            <v-btn :color="task.status == 'pending' ? 'gray' : '' " :outlined="task.status == 'pending'"
                                   @click="task.status = 'pending'" block
                                   class="my-1"
                                   depressed>
                                <div class="d-flex justify-content-between">
                                    <v-icon class="mr-4" x-small>mdi-trending-up</v-icon>
                                    <span>Pending</span>
                                </div>
                            </v-btn>
                            <v-btn :color="task.status == 'process' ? 'blue' : '' " :outlined="task.status == 'process'"
                                   @click="task.status = 'process'" block
                                   class="my-1"
                                   depressed>
                                <div class="d-flex justify-content-between">
                                    <v-icon class="mr-4" x-small>mdi-trending-up</v-icon>
                                    <span>In Progress</span>
                                </div>
                            </v-btn>
                            <v-btn :color="task.status == 'complete' ? 'green' : '' "
                                   :outlined="task.status == 'complete'" @click="task.status = 'complete'" block
                                   class="my-1"
                                   depressed>
                                <div class="d-flex justify-content-between">
                                    <v-icon class="mr-4" x-small>fas fa-check</v-icon>
                                    <span>Completed</span>
                                </div>
                            </v-btn>
                            <div class="text-subtitle-2 font-weight-bold mb-2 text-h2">Priority</div>
                            <v-btn :color="task.priority == 'high' ? 'red' : '' " :dark="task.priority == 'high'"
                                   @click="task.priority = 'high'" block
                                   class="my-1" depressed>
                                <div class="d-flex justify-content-between">
                                    <v-icon class="mr-4" x-small>fas fa-star</v-icon>
                                    <span>Important</span>
                                </div>
                            </v-btn>
                            <v-btn :color="task.priority == 'normal' ? 'blue' : '' " :dark="task.priority == 'normal'"
                                   @click="task.priority = 'normal'" block
                                   class="my-1" depressed>
                                <div class="d-flex justify-content-between">
                                    <v-icon class="mr-4" x-small>fas fa-star</v-icon>
                                    <span>Normal</span>
                                </div>
                            </v-btn>
                            <div class="text-subtitle-2 font-weight-bold mb-2 text-h2 my-3">Action</div>
                            <v-btn @click="redirectToApplicant()" block class="my-1" depressed v-if="task.applicant_id">
                                <div class="d-flex justify-content-between">
                                    <v-icon class="mr-4" x-small>mdi-eye</v-icon>
                                    <span>View Applicant</span>
                                </div>
                            </v-btn>
                            <v-autocomplete
                                    :items="applicant_users"
                                    :loading="isBusy"
                                    :search-input.sync="searchName"
                                    class="mt-4"
                                    clearable
                                    dense
                                    item-text="display_text"
                                    item-value="id"
                                    label="Applicant Name or Email"
                                    v-model="task.applicant_id"
                            >
                                <template slot="selection" slot-scope="data">
                                    <!-- HTML that describe how select should render selected items -->
                                    {{ data.item.first_name }} - {{ data.item.last_name }}- {{ data.item.email }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    {{ data.item.first_name }} - {{ data.item.last_name }}- {{ data.item.email }}
                                </template>
                            </v-autocomplete>
                            <v-btn @click="showDeadline=true" block class="my-1" depressed>
                                <div class="d-flex justify-content-between">
                                    <v-icon class="mr-4" x-small>fas fa-calendar</v-icon>
                                    <span>Deadline</span>
                                </div>
                            </v-btn>
                            <v-sheet v-if="showDeadline">
                                <v-row class="mt-2">
                                    <v-col cols="12">
                                        <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                min-width="auto"
                                                offset-y
                                                transition="scale-transition"
                                                v-model="menu2"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        dense
                                                        hide-details
                                                        label="Deadline Date"
                                                        outlined
                                                        prepend-inner-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-model="task.due_date"
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                    @input="menu2 = false"
                                                    v-model="task.due_date"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="mb-2" cols="6">
                                        <vue-timepicker :format="time_format" manual-input
                                                        v-model="task.due_time"></vue-timepicker>
                                    </v-col>
                                    <v-col class="mb-2" cols="6">
                                        <v-checkbox label="reminder" v-model="task.reminder"></v-checkbox>
                                    </v-col>
                                </v-row>

                            </v-sheet>

                            <v-btn @click="__delete(task.id)" block class="my-1" color="red" dark depressed
                                   v-if="task.id">
                                <div class="d-flex justify-content-between">
                                    <v-icon class="mr-4" x-small>fas fa-trash</v-icon>
                                    <span>Delete</span>
                                </div>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            :loading="loading"
                            @click="createOrUpdate()"
                            class="btn btn-primary"
                            dark
                            medium
                    >
                        Save
                    </v-btn>
                    <v-btn
                            @click="closeDialog"
                            color="mr-2" text dark medium>
                        Cancel
                    </v-btn>

                </v-card-actions>
                <v-snackbar
                        :timeout="timeout"
                        absolute
                        bottom
                        right
                        v-model="snackbar"
                >
                  <span class="text-white">
                        {{ text }}
                  </span>


                </v-snackbar>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import VueTimepicker from "vue2-timepicker";
    import 'vue2-timepicker/dist/VueTimepicker.css';
    import TaskService from "@/services/task-manager/task/TaskService";
    import {required} from "vuelidate/lib/validators";
    import GeneralMixin from "@/mixins/GeneralMixin";
    import TaskChecklistService from "@/services/task-manager/checklist/TaskChecklistService";
    import TaskCommentService from "@/services/task-manager/comment/TaskCommentService";
    import AdminUserService from "@/services/admin-user/AdminUserService";

    const adminUser = new AdminUserService;
    const taskService = new TaskService();
    const taskChecklistService = new TaskChecklistService();
    const taskCommentService = new TaskCommentService();
    export default {
        name: "ApplicantTaskDetail",
        mixins: [GeneralMixin],
        props:['applicant_id'],
        components: {
            VueTimepicker,
        },
        watch: {
            isUpdating(val) {
                if (val) {
                    setTimeout(() => (this.isUpdating = false), 3000)
                }
            },
            searchName(newValue, oldValue) {
                this.applicant_search.name = newValue;
                // Lazily load input items
                this.getAllApplicantUsers();

            },
        },
        validations: {
            task: {
                title: {required},
                description: {required},
            },
        },
        data() {
            const srcs = {
                1: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
                2: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                3: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
                4: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
                5: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
            }
            return {
                editorConfig: {
                  versionCheck: false,
                  scayt_autoStartup: true,
                  allowedContent: true,
                  pasteFromWordRemoveFontStyles: true,
                  pasteFromWordRemoveStyles: true,
                  pasteFromWordNumberedHeadingToList: true,
                  toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'SpellChecker', 'Scayt', 'PasteFromWord']],
                  contentsCss: [
                      'http://cdn.ckeditor.com/4.17.1/full-all/contents.css',
                      'https://ckeditor.com/docs/ckeditor4/4.17.1/examples/https://ckeditor.com/docs/ckeditor4/4.17.1/examples/assets/css/pastefromgdocs.css'
                  ]
                },
                loading: false,
                searchName: null,
                isUpdating: false,
                isBusy: false,
                currentDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                time_format: 'hh:mm',
                menu2: false,
                dialog: false,
                showDeadline: false,
                addChecklist: false,
                snackbar: false,
                text: '',
                timeout: 2000,
                task: {
                    title: 'Task',
                    description: "Description ",
                    due_date: null,
                    due_time: "",
                    reminder: 0,
                    applicant_id: null,
                    assigned_to: [],
                    priority: 'normal',
                    status: 'pending',
                    is_active: 1,
                },
                task_id: null,
                editedTask: {
                    title: true,
                    description: true,
                    assigned_to: false,
                },
                checklistData: {
                    checklist: null,
                    is_complete: false
                },
                commentData: {
                    comment: null,
                    task_id: null,
                    applicant_id: null,

                },
                checklists: [],
                comments: [],
                admin_users: [],
                assigned_to: [],
            }
        },
        computed: {
            completed_percentage() {
                if (this.checklists.length) {
                    let total_done = 0;
                    let data = this.checklists.filter((item) => {
                        if (item.is_complete)
                            total_done++;
                    })
                    return (total_done / this.checklists.length) * 100;
                }
                return 0;
            }
        },
        methods: {
            open() {
                this.reset();
                this.dialog = true;
            },
            edit(id) {
                this.reset();
                this.task_id = id;
                this.dialog = true;
                this.taskDetail();

            },
            reset() {
                this.task_id = null;
                this.task = {
                    title: 'Task',
                    description: "Description ",
                    due_date: null,
                    due_time: "",
                    reminder: 0,
                    assigned_to: [],
                    priority: 'normal',
                    status: 'pending',
                    is_active: 1,
                }
                if (this.applicant_id){
                    this.task.applicant_id = this.applicant_id;
                }
                this.comments = [];
                this.checklists = [];
                // this.editedTask = {
                //     title: false,
                //     description: false,
                //     assigned_to: false,
                // }
            },
            closeDialog() {
                this.reset();
                this.$emit('refresh');
                this.dialog = false;
            },
            test(field) {
                alert('clicked')
            },
            redirectToApplicant() {
                this.$router.push({
                    name: 'student-profile',
                    params: {user_id: this.task.applicant_id}
                })
            },
            taskDetail() {
                if (this.task_id != null || this.task_id != undefined) {
                    taskService
                        .show(this.task_id)
                        .then(response => {
                            this.editedTask.title = false;
                            this.editedTask.description = false;
                            this.task = response.data.task;
                            this.commentData.task_id = this.task.id;
                            this.commentData.applicant_id = this.task.applicant_id ? this.task.applicant_id : null;
                            this.assigned_to = this.task.assigned_to;
                            this.__getCheckList();
                            this.__getComments();
                        })
                }
            },

            createOrUpdate() {
                this.$v.task.$touch()
                if (this.$v.task.$error) {
                    setTimeout(() => {
                        this.$v.task.$reset()
                    }, 3000);
                } else {
                    if (this.assigned_to.length) {
                        this.task.assigned_to = this.assigned_to.join(',');
                    }
                    if (!this.task.id) this._create();
                    else this.__update();
                }
            },
            _create: function () {
                this.loading = true;
                taskService
                    .create(this.task)
                    .then(response => {
                        this.$snotify.success("Task created successfully");
                        this.closeDialog();
                        this.resetForm();
                        this.errors = [];
                        this.loading = false;
                        this.$emit('refresh');
                    })
                    .catch(error => {
                        this.errors = error.errors;
                    })
            },
            __update: function () {
                this.loading = true;
                taskService
                    .update(this.task.id, this.task)
                    .then(response => {
                        this.$snotify.success("Education Level updated successfully");
                        this.closeDialog();
                        this.resetForm();
                        this.errors = [];
                        this.loading = false;
                        this.$emit('refresh');
                    })
                    .catch(error => {
                        this.errors = error.errors;
                    })
            },
            __delete(id) {
                this.$confirm({
                    message: `Are you sure? `,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    callback: (confirm) => {
                        if (confirm) {
                            taskService
                                .delete(id)
                                .then((response) => {
                                    this.closeDialog();
                                    this.$snotify.success("Task Deleted Successfully ");
                                })
                                .catch((err) => {
                                    this.$snotify.error("Oops something went wrong");
                                });
                        }
                    },
                });
            },


            //checklist methods
            __getCheckList() {
                taskChecklistService.paginate(this.task_id).then(res => {
                    this.checklists = res.data;
                }).catch(err => {
                    // console.log(err)
                })
            },
            __addList() {
                taskChecklistService.create(this.task_id, this.checklistData).then(res => {
                    this.checklists.push(this.checklistData);
                    this.checklistData = {
                        checklist: null,
                        is_complete: false
                    };
                    this.text = 'Checklist Added';
                    this.snackbar = true;
                    this.__getCheckList();
                }).catch(err => {
                    // console.log(err)
                })
            },

            toogleChecklist(item) {
                taskChecklistService.update(this.task_id, item.id, item).then(res => {
                    this.text = item.is_complete ? 'Task Completed' : 'Task Incompleted';
                    this.snackbar = true;
                    // this.$snotify.success('Task Completed');
                }).catch(error => {
                    this.$snotify.error('Something  Wrong');
                })
            },


            __removeTask(index) {
                this.checklists.splice(index, 1)
            },

            // checklist methodds end

            // comments methods
            __getComments() {
                taskCommentService.paginate(this.task_id).then(res => {
                    this.comments = res.data;
                }).catch(err => {
                    // console.log(err)
                })
            },
            __addComment() {
                taskCommentService.create(this.task_id, this.commentData).then(res => {
                    this.__getComments();
                    this.commentData = {
                      comment: null,
                      task_id: null,
                      applicant_id: null,

                    };
                }).catch(err => {
                    // console.log(err)
                })

            },
            // comments methods end


            remove(item) {
                const index = this.friends.indexOf(item.name)
                if (index >= 0) this.friends.splice(index, 1)
            },
            getAllAdminUsers() {
                adminUser
                    .all()
                    .then(response => {
                        this.admin_users = response.data.adminUsers;
                    })
                    .catch(err => {
                        // console.log(err)
                    })
            }
        },
        mounted() {

            this.getAllApplicantUsers();
            this.getAllAdminUsers();

        }
    }
</script>

<style scoped>

    .vue__time-picker {
        display: initial !important;
    }

</style>
