import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ApplicationHistoryService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/application-history';
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

}