<template>
  <application-discussion :support="true" :user_id="user_id"></application-discussion>
</template>

<script>
import ApplicationDiscussion
  from "@/view/pages/view/user/student/profile/components/application-discussion/ApplicationDiscussion";
export default {
  name: "Support",
  props: ['user_id'],
  components: {ApplicationDiscussion}
}
</script>

<style scoped>

</style>