<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Education History</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                  v-model="educationHistory.country_of_education_id"
                  :error="$v.educationHistory.country_of_education_id.$error"
                  :items="countries"
                  item-text="title"
                  item-value="id"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Country Of Education <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.educationHistory.country_of_education_id.$error"
              >This Country Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  label="Education Level"
                  v-model="educationHistory.education_level_id"
                  :error="$v.educationHistory.education_level_id.$error"
                  :items="education_levels"
                  item-text="title"
                  item-value="id"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Education Level <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.educationHistory.education_level_id.$error"
              >This Education LevelField is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  label="Education Level"
                  v-model="educationHistory.grading_scheme_id"
                  :error="$v.educationHistory.grading_scheme_id.$error"
                  :items="grading_schemes"
                  item-text="title"
                  item-value="id"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Grading Scheme <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.educationHistory.grading_scheme_id.$error"
              >This Grading Scheme Field is Required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menu"
                  v-model="menuStart"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="educationHistory.start_date"
                      :error="$v.educationHistory.start_date.$error"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Start Date <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="educationHistory.start_date"
                    outlined
                    dense
                    no-title
                    @input="menuStart = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.educationHistory.start_date.$error">This Start Date Field is Required.</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menu"
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="educationHistory.end_date"
                      :error="$v.educationHistory.end_date.$error"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      End Date <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="educationHistory.end_date"
                    outlined
                    dense
                    no-title
                    @input="menuEnd = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.educationHistory.end_date.$error">This End Date Field is Required.</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  label="Education Level"
                  v-model="educationHistory.status"
                  :error="$v.educationHistory.status.$error"
                  :items="statusTypes"
                  item-text="name"
                  item-value="value"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Status <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.educationHistory.status.$error"
              >This Status Field is Required</span
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="mr-2" text dark medium @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          {{ edit ? 'Update' : 'Save' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import UserEducationHistoryService from "@/services/user/education-history/UserEducationHistoryService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import GradingSchemeService from "@/services/grading-scheme/GradingSchemeService";
import CountryService from "@/services/country/CountryService";

const userEducationHistory = new UserEducationHistoryService();
const country = new CountryService();
const educationLevel = new EducationLevelService();
const gradingScheme = new GradingSchemeService();
export default {
  name: "EducationHistory",
  data(){
    return{
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      menuStart: false,
      menuEnd: false,
      errors: [],
      country_of_education: [],
      education_levels: [],
      grading_schemes: [],
      userId: '',
      statusTypes: [
        { name: 'OnGoing', value: 'ongoing'},
        { name: 'Completed', value: 'completed'},
      ],
      educationHistory: {
        user_id: '',
        country_of_education_id: '',
        education_level_id: '',
        grading_scheme_id: '',
        start_date: '',
        end_date: '',
        status: ''
      },
    }
  },
  validations:{
    educationHistory:{
      country_of_education_id: {required},
      education_level_id: {required},
      grading_scheme_id: {required},
      start_date: {required},
      end_date: {required},
      status: {required},
    }
  },
  mounted() {
    this.userId = this.$route.params.user_id;
    this.getAllCountryOfEducation();
    this.getAllEducationLevels();
    this.getAllGradingSchemes();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    createEducationHistory() {
      this.dialog = true;
      this.title = 'Add';
    },
    editEducationHistory(item) {
      this.dialog = true;
      this.edit = true;
      this.title = 'Edit';
      this.educationHistory = item;
    },
    getAllCountryOfEducation() {
      country.getAllCountry({'is_operating_country':1}).then(response => {
        this.countries = response.data.data;
      }).catch(() => {})
    },
    getAllEducationLevels() {
      educationLevel.all().then(response => {
        this.education_levels = response.data.educationLevels;
      }).catch(() => {})
    },
    getAllGradingSchemes() {
      gradingScheme.all().then(response => {
        this.grading_schemes = response.data.gradingSchemes;
      }).catch(() => {})
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      userEducationHistory
          .create(this.userId, this.educationHistory)
          .then(response => {
            this.$snotify.success(`Schooling History Created Successfully`);
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.loading = false;
          })
    },
    update: function () {
      this.loading = true;
      userEducationHistory
          .update(this.userId, this.educationHistory.id, this.educationHistory)
          .then(response => {
            this.$snotify.success(`Schooling History Update Successfully`);
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.loading = false;
          })
    },
    resetForm() {
      this.educationHistory = {
        user_id: '',
        country_of_education_id: '',
        education_level_id: '',
        grading_scheme_id: '',
        start_date: '',
        end_date: '',
        status: ''
      };
    },
  },
}
</script>

<style scoped>

</style>