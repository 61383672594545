import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class UserDocumentService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user';
    }


    sort(userId, data = []) {
        let url = `${this.#api}/${userId}/document/sort`;
        return apiService.post(url, data);
    }

    paginate(userId, data = {}, index = null) {
        let url = `${this.#api}/${userId}/document`;
        if (index != null)
            url = `${url}/?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(userId, data) {
        let url = `${this.#api}/${userId}/document`;
        console.log(data)
        return apiService.post(url, data)
    }

    show(userId, id) {
        let url = `${this.#api}/${userId}/document/${id}`
        return apiService.get(url)
    }

    update(userId, id, data) {
        let url = `${this.#api}/${userId}/document/${id}/update`
        return apiService.post(url, data)
    }

    delete(userId, id) {
        let url = `${this.#api}/${userId}/document/${id}`
        return apiService.delete(url);
    }


    createOrUpdate(data) {
        let url = `${this.#api}/user-documents/create-or-update/data`
        return apiService.post(url, data);
    }
}