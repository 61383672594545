<template>
    <v-app>
        <v-card raised>
            <v-card-title>
                Assign Branch Office
                <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>        
                <v-row>
                    <v-col cols="10">
                        <v-select
                            outlined
                            v-model="user.branch_id"
                            dense
                            :items="ourBranches"
                            item-text="name"
                            item-value="id"
                        >
                            <template v-slot:label>
                                Select branch to assign
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-btn
                            class="btn btn-primary"
                            medium
                            @click="saveBranch()"
                            :loading="loading"
                        >
                        Save
                        </v-btn>
                    </v-col>
                </v-row>        
            </v-card-text>

            <v-card-actions></v-card-actions>
        </v-card>
  </v-app>
</template>

<script>
import UserService from "@/services/user/UserService";

const user = new UserService();

export default {
    props: ['user_id'],
    data(){
        return{
          loading: false,
          user:{
            branch_id : null
          },
        }
    },
    methods:{
        saveBranch(){
            this.loading = true;
            user
            .updateBranch(this.user_id, this.user)
            .then((response) => {
                this.$snotify.success('Branch assigned successfully');
            })
            .catch((err) => {
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        getUser(){
            user
            .show(this.user_id)
            .then((response) => {
                this.user = response.data.user;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        }
    },
    mounted(){
        this.getAllBranches();
        this.getUser();
    }
}
</script>
