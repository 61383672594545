import { render, staticRenderFns } from "./Support.vue?vue&type=template&id=7f1fe460&scoped=true&"
import script from "./Support.vue?vue&type=script&lang=js&"
export * from "./Support.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f1fe460",
  null
  
)

export default component.exports